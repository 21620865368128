<template>
    <div>
        <div class="inline-flex space-x-3 mr-5 mt-3 text-custom-blue">
            <button class="h-4 w-4 has-tooltip" @click="showEditDialog = true">
                <span class="tooltip">Edit</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
            </button>
            <button class="h-4 w-4 has-tooltip" @click="showDeleteConfirmation = true">
                <span class="tooltip">Delete</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </button>
        </div>
        <Dialog v-if="showEditDialog" @closeDialog="showEditDialog = false">
            <template v-slot:title>
                <span class="font-semibold">Questionnaire</span>
            </template>
            <template v-slot:body>
                <div class="m-5 text-custom-blue">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Question</span>
                            <span v-if="hasError('question')" class="text-xs text-red-500">{{ errors.question }}</span>
                        </div>
                        <textarea v-model="questionForm.question" cols="30" rows="5" placeholder="Enter question" :class="{'border-red-500': hasError('question') }"></textarea>
                    </div>
                    <div class="flex flex-col mt-3">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Answer Type</span>
                            <span v-if="hasError('type')" class="text-xs text-red-500">{{ errors.type }}</span>
                        </div>
                        <div class="inline-flex justify-left items-center space-x-2 p-2" :class="{'border-red-500 border rounded': hasError('type') }">
                            <div>
                                <input v-model="questionForm.type" type="radio" id="yesno" value="1" name="type">
                                <label for="yesno">Yes/No</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="file" value="2" name="type">
                                <label for="file">File Upload</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="text" value="3" name="type">
                                <label for="text">Text</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="url" value="4" name="type">
                                <label for="url">URL</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex items-center space-x-2">
                            <input type="checkbox" v-model="questionForm.is_required" id="required">
                            <label for="required" class="font-semibold">Is required?</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="updateQuestion">Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showDeleteConfirmation" @deleteItem="deleteQuestion" @cancel="showDeleteConfirmation = false">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete this question?
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import Dialog from '../../../reusables/Dialog'
    import Confirmation from '../../../reusables/Confirmation'

    export default {
        components: {
            Dialog,
            Confirmation
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            position: {
                type: Object
            }
        },
        data: () => ({
            showEditDialog: false,
            questionForm: {},
            errors: {},
            showDeleteConfirmation: false
        }),
        methods: {
            updateQuestion(){
                this.$emit('update', {
                    question: this.questionForm,
                    old_question: this.question.question,
                    position: this.position
                })
                this.showEditDialog = false
            },
            deleteQuestion(){
                this.$emit('delete', this.position)
                this.showDeleteConfirmation = false
            },
            validate(){
                this.errors = {}
                if(!this.questionForm.question){
                    this.errors.question = 'Required'
                }
                if(!this.questionForm.type){
                    this.errors.type = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
        },
        mounted(){
            Object.assign(this.questionForm, this.question)
        }
    }
</script>