<template>
    <div>
        <template v-if="!showCompletePage">
            <div class="border rounded px-5 py-2 border-red-500 bg-red-50 text-red-500 text-xs" v-if="isPeerReviewer && !isAdminStatusComplete">You may start reviewing when the HZ CPA Team status is complete.</div>
            <Stepper class="mt-5" :active-step="activeStep" :steps="filteredSteps" />
            <div class="bg-white overflow-auto mt-5 rounded py-3 h-4/5 relative" v-if="!showCompletePage && projectAudit.hasOwnProperty('client_info')">
                <div class="m-5 flex flex-col border bg-white rounded" v-if="activeStep == 'Client Info'">
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Client Name</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.name }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Client Email</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.email }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Contact Number</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.contact_number }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Address Line 1</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.address_line_1 }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Address Line 2</span>
                        <span class="ml-5 col-span-3">
                            <template v-if="projectAudit.client_info.address_line_2 != ''">
                                {{ projectAudit.client_info.address_line_2 }}
                            </template>
                            <template v-else>-</template>
                        </span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">City</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.city }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">State</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.state }}</span>
                    </div>
                    <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                        <span class="text-gray-400">Zip Code</span>
                        <span class="ml-5 col-span-3">{{ projectAudit.client_info.zip_code }}</span>
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.priorAuditorResponse" :key="`par-${index}`" class="" v-show="activeStep == 'Prior Auditor Response'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.priorAuditorResponse[index].answer" :is-disabled="isTeam || isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        <!-- <PrepareReview v-model="questionnaireForm.priorAuditorResponse[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" /> -->
                        <Edit :position="{ procedure: 'priorAuditorResponse', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.fraudRisk" :key="`fr-${index}`" v-show="activeStep == 'Fraud Risk'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <template v-if="isTeam">
                            <Question :content="question" :has-multiple-answers="true" :multiple-answers="fraudRiskAnswers[index]" :is-disabled="isTeam || isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        </template>
                        <template v-else>
                            <Question :content="question" v-model="questionnaireForm.fraudRisk[index]['individual_answer_'+user.id]" :is-disabled="isTeam || isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        </template>
                        <!-- <PrepareReview v-model="questionnaireForm.fraudRisk[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" /> -->
                        <Edit :position="{ procedure: 'fraudRisk', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.internalControl" :key="`ic-${index}`"  v-show="activeStep == 'Internal Control'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.internalControl[index].answer" :is-disabled="isTeam || isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        <!-- <PrepareReview v-model="questionnaireForm.internalControl[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" /> -->
                        <Edit :position="{ procedure: 'internalControl', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.documentRequest" :key="`dr-${index}`" v-show="activeStep == 'Document Request'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.documentRequest[index].answer" :is-disabled="isTeam || isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        <!-- <PrepareReview v-model="questionnaireForm.documentRequest[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" /> -->
                        <Edit :position="{ procedure: 'documentRequest', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.threeMonthReview" :key="`tm-${index}`" v-show="activeStep == '3 Month Review'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.threeMonthReview[index].answer" :is-disabled="isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        <PrepareReview v-model="questionnaireForm.threeMonthReview[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" />
                        <Edit :position="{ procedure: 'threeMonthReview', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-else-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.fourthToTwelfthMonthReview" :key="`fm-${index}`" v-show="activeStep == '4-12 Month Review'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.fourthToTwelfthMonthReview[index].answer" :is-disabled="isPeerReviewer" :class="{'border border-red-500' : hasError(question.id)}" />
                        <PrepareReview v-model="questionnaireForm.fourthToTwelfthMonthReview[index].answer" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" />
                        <Edit :position="{ procedure: 'fourthToTwelfthMonthReview', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-else-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-for="(question, index) in this.questionnaireForm.yearEndReview" :key="`ye-${index}`" v-show="activeStep == 'Year End Review'">
                    <div class="inline-flex justify-between w-full border-b" v-if="(question.hasOwnProperty('status') && question.status != 'deleted') || !question.hasOwnProperty('status')">
                        <Question :content="question" v-model="questionnaireForm.yearEndReview[index]" :is-disabled="isPeerReviewer" />
                        <PrepareReview v-model="questionnaireForm.yearEndReview[index].admin" @save="adminSave" v-if="(isTeam || isPeerReviewer) && editMode == false" :is-disabled="isPeerReviewer" />
                        <Edit :position="{ procedure: 'yearEndReview', index: index }" :question="question" @update="updateQuestionnaire" @delete="deleteQuestion" v-else-if="isSuperAdminOrAdmin && editMode == true" />
                    </div>
                </div>
                <div v-if="activeStep == 'Peer Review'">
                    <div class="m-5 flex flex-col">
                        <span class="text-sm font-semibold mb-5">Review</span>
                        <wysiwyg v-model="peerReview" cols="30" rows="10" v-if="!isTeam && !isPeerReviewerStatusComplete" />
                        <p class="text-sm" v-else v-html="peerReview"></p>
                    </div>
                </div>
            </div>
            <div class="mt-3 bg-white inline-flex space-x-5 w-full p-2 justify-end rounded">
                <!-- Edit Mode for Admin and Super Admin -->
                <button class="btn-small btn-tertiary" @click="editMode = true" v-if="isSuperAdminOrAdmin && editMode == false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>Edit Questions</button>
                <button class="btn-small btn-secondary" @click="saveAndNotifyClient" v-else-if="isSuperAdminOrAdmin && editMode == true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>Save &amp; Notify Client</button>
                <!-- end of edit mode -->
                <button class="btn-small btn-tertiary" @click="showConfirmation = true" v-if="!isCompleted && !isPeerReviewer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>Continue Later</button>
                <!-- this button below is for peer reviewer only -->
                <button class="btn-small btn-tertiary" @click="savePeerReview(false)" v-if="!isCompleted && isPeerReviewer && isAdminStatusComplete">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>Continue Later</button>
                <button class="btn-small" :class="activeStep == filteredSteps[0].name ? 'btn-disabled' : 'btn-tertiary'" @click="moveStep('prev')" :disabled="activeStep == filteredSteps[0].name">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>Prev</button>
                <button class="btn-small" :class="activeStep != filteredSteps[filteredSteps.length-1].name ? 'btn-primary' : 'btn-disabled'" @click="moveStep('next')" :disabled="activeStep == filteredSteps[filteredSteps.length-1].name">
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
                <!-- this save button below is for hz cpa team only -->
                <button class="btn-small btn-tertiary" @click="save(false)" v-if="isTeam && isProjectAuditExternalStatusComplete">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>Save</button>
                <button class="btn-small btn-primary" @click="submitConfirmation" v-if="!isCompleted && activeStep == filteredSteps[filteredSteps.length-1].name && !isPeerReviewer">
                    Submit
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                </button>
                <button class="btn-small btn-primary" @click="showPeerReviewSubmitConfirmation = true" v-if="!isCompleted && activeStep == filteredSteps[filteredSteps.length-1].name && isPeerReviewer && isAdminStatusComplete">
                    Submit
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                </button>
            </div>
        </template>
        <div v-else>
            <div class="m-5 flex flex-col items-center justify-center space-y-10">
                <span class="inline-flex text-lg font-bold items-center">Thank you for answering
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-green-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </span>
                <button class="btn btn-secondary" @click="closeWindow">Close this window</button>
            </div>
        </div>
        <OkayCancel v-if="showConfirmation" @cancel="showConfirmation = false" @success="save(false)">
            <template v-slot:message>
                Would you like to save and continue later?
            </template>
        </OkayCancel>
        <OkayCancel v-if="showSubmitConfirmation" @cancel="showSubmitConfirmation = false" @success="save(true)">
            <template v-slot:message>
                Would you like to submit?
            </template>
        </OkayCancel>
        <OkayCancel v-if="showPeerReviewSubmitConfirmation" @cancel="showPeerReviewSubmitConfirmation = false" @success="savePeerReview(true)">
            <template v-slot:message>
                Would you like to submit?
            </template>
        </OkayCancel>
    </div>
</template>
<script>
    import Stepper from './Stepper'
    import Edit from './Edit'
    import PrepareReview from './PrepareReview'
    import Question from '../../../reusables/Question'
    import OkayCancel from '../../../reusables/OkayCancel'
    import { mapState, mapGetters } from 'vuex'

    export default {
        components: {
            Stepper,
            PrepareReview,
            Question,
            OkayCancel,
            Edit
        },  
        data: () => ({
            steps: [
                {
                    name: 'Client Info',
                    procedure_step_id: null,
                    status: 'active',
                    enabledFor: 'team,peer reviewer'
                },
                {
                    name: 'Prior Auditor Response',
                    procedure_step_id: 1,
                    group: 'External',
                    status: 'pending',
                    enabledFor: 'team,prior auditor,peer reviewer'
                },
                {
                    name: 'Fraud Risk',
                    procedure_step_id: 2,
                    group: 'External',
                    status: 'pending',
                    enabledFor: 'team,client,peer reviewer'
                },
                {
                    name: 'Internal Control',
                    procedure_step_id: 3,
                    group: 'External',
                    status: 'pending',
                    enabledFor: 'team,client,peer reviewer'
                },
                {
                    name: 'Document Request',
                    procedure_step_id: 4,
                    group: 'External',
                    status: 'pending',
                    enabledFor: 'team,client,peer reviewer'
                },
                {
                    name: '3 Month Review',
                    procedure_step_id: 5,
                    group: 'Internal',
                    status: 'pending',
                    enabledFor: 'team,peer reviewer'
                },
                {
                    name: '4-12 Month Review',
                    procedure_step_id: 6,
                    group: 'Internal',
                    status: 'pending',
                    enabledFor: 'team,peer reviewer'
                },
                {
                    name: 'Year End Review',
                    procedure_step_id: 7,
                    group: 'Internal',
                    status: 'pending',
                    enabledFor: 'team,peer reviewer'
                },
                {
                    name: 'Peer Review',
                    procedure_step_id: null,
                    status: 'pending',
                    enabledFor: 'team,peer reviewer'
                }
            ],
            questionnaireForm: {
                priorAuditorResponse: [],
                fraudRisk: [],
                internalControl: [],
                documentRequest: [],
                threeMonthReview: [],
                fourthToTwelfthMonthReview: [],
                yearEndReview: [],
            },
            fraudRiskAnswers: [],
            attachments: {},
            showConfirmation: false,
            showSubmitConfirmation: false,
            errorIds: [],
            isCompleted: true,
            peerReview: '',
            showPeerReviewSubmitConfirmation: false,
            showCompletePage: false,
            editMode: false,
            questionnaireChangeLog: {},
            hasChanges: false
        }),
        computed: {
            ...mapState({
                projectAudit: state => state.clientAuditProcedureModule.projectAudit,
                user: state => state.user
            }),
            ...mapGetters(['isTeam', 'isPriorAuditor', 'isPeerReviewer', 'isClientUser', 'isSuperAdmin', 'isSuperAdminOrAdmin']),
            ...mapGetters('clientAuditProcedureModule', ['isProjectAuditExternalStatusComplete', 'isAdminStatusComplete', 'isPeerReviewerStatusComplete']),
            filteredSteps(){
                let user = ''
                if(this.isClientUser){
                    user = 'client'
                    this.isCompleted = this.projectAudit.client_status == 'Complete'
                }
                else if(this.isPriorAuditor){
                    user = 'prior auditor'
                    this.isCompleted = this.projectAudit.prior_auditor_status == 'Complete'
                }
                else if(this.isPeerReviewer){
                    user = 'peer reviewer'
                    this.isCompleted = this.projectAudit.peer_reviewer_status == 'Complete'
                }
                else if(this.isTeam){
                    user = 'team'
                }
                
                let enabledSteps = this.steps.filter(step => step.enabledFor.includes(user))
                for(let i = 0; i < enabledSteps.length; i++){
                    if(i == 0){
                        enabledSteps[i].status = 'active'
                    }
                    enabledSteps[i].step = i+1
                }
                return enabledSteps
            },
            activeStep(){
                return this.filteredSteps.filter(step => step.status == 'active')[0].name
            }
        },
        methods: {
            moveStep(action){
                if(!this.isTeam && !this.isPeerReviewer){
                    // temporarily removed validation
                    // this.validate()
                }
                let activeStep = this.filteredSteps.filter(step => step.status == 'active')
                for(let i = 0; i < this.filteredSteps.length; i++){
                    this.filteredSteps[i].step = i+1
                }

                if(action == 'next'){
                    if(this.errorIds.length == 0){
                        this.filteredSteps[activeStep[0].step-1].status = 'done'
                        this.filteredSteps[activeStep[0].step].status = 'active'
                    }
                    else{
                        this.$store.commit('SET_SNACKBAR', {
                            message: 'Please answer all required questions',
                            color: 'red'
                        })
                    }
                }
                else{
                    this.filteredSteps[activeStep[0].step-2].status = 'active'
                    this.filteredSteps[activeStep[0].step-1].status = 'pending'
                }
            },
            setQuestions(){
                this.questionnaireForm.priorAuditorResponse = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 1)
                this.questionnaireForm.fraudRisk = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 2)
                if(this.isTeam){
                    this.fraudRiskAnswers = this.questionnaireForm.fraudRisk.map(question => {
                        const keys = Object.keys(question)
                        const answersIndices = keys.filter(key => key.indexOf('individual_answer_') != -1)
                        let answers = []
                        answersIndices.forEach((index) => answers.push(question[index]))
                        return answers
                    })
                }
                this.questionnaireForm.internalControl = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 3)
                this.questionnaireForm.documentRequest = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 4)
                this.questionnaireForm.threeMonthReview = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 5)
                this.questionnaireForm.fourthToTwelfthMonthReview = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 6)
                this.questionnaireForm.yearEndReview = this.projectAudit.questionnaire.filter(question => question.procedure_step_id == 7)
                this.peerReview = this.projectAudit.peer_review
            },
            async save(isComplete){
                this.$store.dispatch('setLoading', true)
                this.showSubmitConfirmation = false
                this.collectFiles()
                this.showConfirmation = false
                let activeForms = this.filteredSteps.map(step => step.procedure_step_id)
                let payload = {
                    isComplete: isComplete,
                    questionnaire: [
                        ...this.questionnaireForm.priorAuditorResponse, 
                        ...this.questionnaireForm.fraudRisk, 
                        ...this.questionnaireForm.internalControl, 
                        ...this.questionnaireForm.documentRequest,
                        ...this.questionnaireForm.threeMonthReview,
                        ...this.questionnaireForm.fourthToTwelfthMonthReview,
                        ...this.questionnaireForm.yearEndReview
                    ],
                    projectAuditId: this.$route.params.id,
                    activeForms: activeForms
                }

                if(Object.keys(this.attachments).length){
                    payload['attachments'] = this.attachments
                }
                await this.$store.dispatch('clientAuditProcedureModule/saveQuestionnaire', payload)
                this.showCompletePage = isComplete
                this.$store.dispatch('setLoading', false)
            },
            //for prepare and review
            async adminSave(){
                this.$store.dispatch('setLoading', true)
                let questionnaire = [
                    ...this.questionnaireForm.priorAuditorResponse, 
                    ...this.questionnaireForm.fraudRisk, 
                    ...this.questionnaireForm.internalControl, 
                    ...this.questionnaireForm.documentRequest,
                    ...this.questionnaireForm.threeMonthReview,
                    ...this.questionnaireForm.fourthToTwelfthMonthReview,
                    ...this.questionnaireForm.yearEndReview
                ]

                //check if there are items that isn't prepared or reviewed yet
                let isComplete = false

                for(let i = 0; i < questionnaire.length; i++){
                    if(questionnaire[i].hasOwnProperty('admin')){
                        if(questionnaire[i].admin.hasOwnProperty('preparer') && questionnaire[i].admin.hasOwnProperty('reviewer')){
                            isComplete = true
                        }
                        else{
                            isComplete = false
                            break
                        }
                    }
                    else{
                        isComplete = false
                        break
                    }
                }

                await this.$store.dispatch('clientAuditProcedureModule/saveAdminReview', {
                    isComplete: isComplete,
                    questionnaire: questionnaire,
                    projectAuditId: this.$route.params.id
                })
                this.$store.dispatch('setLoading', false)
            },
            async savePeerReview(isComplete){
                this.$store.dispatch('setLoading', true)
                this.showPeerReviewSubmitConfirmation = false
                await this.$store.dispatch('clientAuditProcedureModule/savePeerReview', {
                    isComplete: isComplete,
                    peerReview: this.peerReview,
                    projectAuditId: this.$route.params.id
                })
                this.showCompletePage = isComplete
                this.$store.dispatch('setLoading', false)
            },
            validate(){
                this.errorIds = []
                if(this.activeStep == 'Prior Auditor Response'){
                    let ids = this.questionnaireForm.priorAuditorResponse.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == 'Fraud Risk'){
                    let ids = this.questionnaireForm.fraudRisk.filter(question => this.isFraudRiskEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == 'Internal Control'){
                    let ids = this.questionnaireForm.internalControl.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == 'Document Request'){
                    let ids = this.questionnaireForm.documentRequest.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == '3 Month Review'){
                    let ids = this.questionnaireForm.threeMonthReview.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == '4-12 Month Review'){
                    let ids = this.questionnaireForm.fourthToTwelfthMonthReview.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
                if(this.activeStep == 'Year End Review'){
                    let ids = this.questionnaireForm.yearEndReview.filter(question => this.isEmpty(question))
                    ids.forEach(question => this.errorIds.push(question.id))
                }
            },
            isEmpty(question){
                return (!question.hasOwnProperty('answer') || question.answer.answer == '') && question.is_required == 1
            },
            isFraudRiskEmpty(question){
                const index = `individual_answer_${this.user.id}`
                return (!question.hasOwnProperty(index) || question[index].answer == '') && question.is_required == 1
            },
            hasError(id){
                return this.errorIds.includes(id)
            },
            submitConfirmation(){
                // this.validate()
                // if(this.errorIds.length == 0){
                    this.showSubmitConfirmation = true
                // }
            },
            collectFiles(){
                this.attachments = {}
                for(const [key, value] of Object.entries(this.questionnaireForm)){
                    value.forEach(question => {
                        if(question.type == '2'){
                            if(question.procedure_step_id == '2'){//FRAUD RISK
                                const property = `individual_answer_${this.user.id}`
                                if(question.hasOwnProperty(property)){
                                    if(this.attachments.hasOwnProperty(key)){
                                        this.attachments[key].push(question[property])
                                    }
                                    else{
                                        this.attachments[key] = [question[property]]
                                    }
                                }
                            }
                            else{
                                if(question.hasOwnProperty('answer')){
                                    if(this.attachments.hasOwnProperty(key)){
                                        this.attachments[key].push(question.answer)
                                    }
                                    else{
                                        this.attachments[key] = [question.answer]
                                    }
                                }
                            }
                        }
                    })
                }
            },
            closeWindow(){
                window.close()
            },
            updateQuestionnaire(item){
                const procedureMap = [
                    {
                        key: 'priorAuditorResponse',
                        value: 'Prior Auditor Response'
                    },
                    {
                        key: 'fraudRisk',
                        value: 'Fraud Risk'
                    },
                    {
                        key: 'internalControl',
                        value: 'Internal Control'
                    },
                    {
                        key: 'documentRequest',
                        value: 'Document Request'
                    }
                ]
                let procedure = procedureMap.filter(proc => proc.key == item.position.procedure)[0]
                this.questionnaireForm[item.position.procedure][item.position.index].question = item.question.question
                this.questionnaireForm[item.position.procedure][item.position.index].type = item.question.type
                this.questionnaireForm[item.position.procedure][item.position.index].is_required = item.question.is_required
                this.questionnaireForm[item.position.procedure][item.position.index].status = 'updated'

                if(this.questionnaireChangeLog.hasOwnProperty(item.position.procedure)){
                    //remove change log for the same item
                    this.questionnaireChangeLog[procedure.value] = this.questionnaireChangeLog[item.position.procedure].filter(log => log != item.old_question)

                    this.questionnaireChangeLog[procedure.value].push(item.question.question)
                }
                else{
                    this.questionnaireChangeLog[procedure.value] = [item.question.question]
                }

                this.hasChanges = true
            },
            deleteQuestion(position){
                this.$set(this.questionnaireForm[position.procedure][position.index], 'status', 'deleted')  
                this.hasChanges = true
            },
            async saveAndNotifyClient(){
                this.$store.dispatch('setLoading', true)
                this.editMode = false

                await this.$store.dispatch('clientAuditProcedureModule/saveQuestionnaire', {
                    projectAuditId: this.$route.params.id,
                    questionnaire: [
                        ...this.questionnaireForm.priorAuditorResponse, 
                        ...this.questionnaireForm.fraudRisk,
                        ...this.questionnaireForm.internalControl,
                        ...this.questionnaireForm.documentRequest,
                        ...this.questionnaireForm.threeMonthReview,
                        ...this.questionnaireForm.fourthToTwelfthMonthReview,
                        ...this.questionnaireForm.yearEndReview
                    ],
                    questionnaireChangeLog: this.questionnaireChangeLog,
                    hasChanges: this.hasChanges
                })
                await this.$store.dispatch('clientAuditProcedureModule/getProjectAudit', this.$route.params.id)
                this.$store.dispatch('setLoading', false)
                this.questionnaireChangeLog = []
                this.hasChanges = false
            },
            closeConfirmation(evt){
                if(this.questionnaireChangeLog.length > 0){
                    return 'Questionnaire changes were not yet saved. Do you want to close this window?'
                }
            }
        },
        async mounted(){
            await this.$store.dispatch('clientAuditProcedureModule/getProjectAudit', this.$route.params.id)
            this.setQuestions()
            document.addEventListener('beforeunload', this.closeConfirmation())
        }
    }
</script>