<template>
    <div class="border rounded flex bg-white items-center w-64">
        <input type="text" class="h-10 pl-3 focus:outline-none flex-grow" :placeholder="placeholder" v-model="keyword">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 mr-2 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="keyword.length > 0" @click="clear">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 mr-3 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="search">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
    </div>
</template>
<script>
    export default {
        props: {
            placeholder: {
                type: String,
                default: 'Search here'
            }
        },
        data: () => ({
            keyword: ''
        }),
        methods: {
            search(){
                this.$emit('search', this.keyword)
            },
            clear(){
                this.keyword = ''
                this.$emit('clear', null)
            }
        }
    }
</script>