import Vue from 'vue'
import router from '../router'

Vue.mixin({
	data: () => ({
		
	}),
	methods: {
        
	},
    computed: {
        years(){
            const date = new Date()
            let years = []
            let year = date.getFullYear()
            while(year >= 2019){
                years.push(year.toString())
                year--
            }
            return years
        }
    }
})

export default {

}