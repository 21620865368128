<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Settings</span>
        </div>
        <div class="flex flex-col border bg-white rounded mt-5">
            <div class="cursor-pointer grid grid-cols-4 border-b pl-10 items-center py-3 text-sm hover:active-page" :class="{'active-page' : showPasswordForm}" @click="showPasswordForm = !showPasswordForm">
                <span class="text-custom-blue">Change Password</span>
                <span class="text-gray-500 ml-5 col-span-3">Change your current password</span>
            </div>
            <transition
                enter-active-class="transform transition duration-100 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100">
                <div class="border-b" :class="{'-translate-y-full': !showPasswordForm}" v-show="showPasswordForm">
                    <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                        <span class="text-custom-blue">Current Password</span>
                        <div class="rounded inline-flex justify-between items-center h-8 pl-1 pr-2 focus:outline-none border">
                            <input v-model="passwordForm.currentPassword" :type="currentPasswordType" class="pl-3 focus:outline-none" />
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @mousedown="currentPasswordType = 'text'" @mouseup="currentPasswordType = 'password'" v-if="passwordForm.currentPassword != ''">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                        <span class="text-custom-blue">New Password</span>
                        <div class="rounded inline-flex justify-between items-center h-8 pl-1 pr-2 focus:outline-none border">
                            <input v-model="passwordForm.newPassword" :type="newPasswordType" class="pl-3 focus:outline-none" />
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @mousedown="newPasswordType = 'text'" @mouseup="newPasswordType = 'password'" v-if="passwordForm.newPassword != ''">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                        <span class="text-custom-blue">Confirm Password</span>
                        <div class="items-center text-sm" :class="{'border border-red-500' : !isPasswordMatched }">
                            <div class="rounded inline-flex justify-between items-center h-8 pl-1 pr-2 focus:outline-none border w-full">
                                <input v-model="passwordForm.newPasswordConfirmation" :type="newPasswordConfirmationType" class="pl-3 focus:outline-none" />
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @mousedown="newPasswordConfirmationType = 'text'" @mouseup="newPasswordConfirmationType = 'password'" v-if="passwordForm.newPasswordConfirmation != ''">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            </div>
                        </div>
                        <span class="text-xs pl-3 text-red-500" v-if="!isPasswordMatched && passwordForm.newPassword != ''">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                        <span class="pl-3 text-green-500" v-else-if="isPasswordMatched && passwordForm.newPassword != ''">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </span>
                    </div>
                    <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                        <div></div>
                        <div>
                            <button class="btn-small" :class="changePasswordButtonDisabled ? 'btn-disabled' : 'btn-secondary'" :disabled="changePasswordButtonDisabled" @click="changePassword">Change Password</button>
                        </div>
                    </div>
                </div>
            </transition>
            <template v-if="isTeam">
                <div class="cursor-pointer grid grid-cols-4 border-b pl-10 items-center py-3 text-sm hover:active-page" @click="showRateForm = !showRateForm" :class="{'active-page' : showRateForm}">
                    <span class="text-custom-blue">Change Hourly Rate</span>
                    <span class="text-gray-500 ml-5 col-span-3">Change your default hourly rate</span>
                </div>
                <transition
                    enter-active-class="transform transition duration-100 ease-custom"
                    enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                    enter-to-class="translate-y-0 scale-y-100 opacity-100">
                    <div class="border-b" :class="{'-translate-y-full': !showRateForm}" v-show="showRateForm">
                        <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                            <span class="text-custom-blue">Hourly Rate</span>
                            <input type="text" v-model="hourlyRate" class="h-8 pl-3 focus:outline-none border rounded" />
                        </div>
                        <div class="grid grid-cols-3 pl-10 items-center py-3 text-sm">
                            <div></div>
                            <div>
                                <button class="btn-small btn-secondary" @click="changeHourlyRate">Change Rate</button>
                            </div>
                        </div>
                    </div>
                </transition>
            </template>
        </div>
    </div>    
</template>
<script>
    import { mapState, mapGetters } from 'vuex'
    export default {
        data: () => ({
            showPasswordForm: false,
            showRateForm: false,
            passwordForm: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirmation: ''
            },
            isPasswordMatched: true,
            changePasswordButtonDisabled: true,
            currentPasswordType: 'password',
            newPasswordType: 'password',
            newPasswordConfirmationType: 'password',
            hourlyRate: ''
        }),
        watch: {
            passwordForm: {
                deep: true,
                handler(){
                    this.newPasswordChecker()
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.user
            }),
            ...mapGetters(['isTeam'])
        },
        methods: {
            async changePassword(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('authModule/changePassword', this.passwordForm)
                this.$store.dispatch('setLoading', false)
            },
            newPasswordChecker(){
                this.isPasswordMatched = this.passwordForm.newPassword == this.passwordForm.newPasswordConfirmation ? true : false
                this.changePasswordButtonDisabled = !((this.passwordForm.newPassword != '' && this.passwordForm.newPasswordConfirmation != '') && this.isPasswordMatched && this.passwordForm.currentPassword != '')
            },
            async changeHourlyRate(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('userModule/updateHourlyRate', {
                    hourly_rate: this.hourlyRate
                })
                this.$store.dispatch('setLoading', false)
            }
        },
        mounted(){
            this.hourlyRate = this.user.hourly_rate
        }
    }
</script>
