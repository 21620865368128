<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="text-lg font-bold text-custom-blue">List</span>
        </div>
        <DataTable
            :headers="tableHeaders"
            :data="projectAudits"
            :total-items="totalProjectAudits"
            :actions="['externalLink']"
            :item="{id: 'audit_id', name: 'name'}"
            :external-link-path="'questionnaire'" />
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import { mapGetters, mapState } from 'vuex'
    export default {
        components: {
            DataTable
        },
        data: () => ({
            headers: [
                {
                    column: 'Audit Number',
                    prop: 'audit_id'
                },
                {
                    column: 'Project Name',
                    prop: 'project_name'
                },
                {
                    column: 'Year',
                    prop: 'year'
                }
            ],
        }),
        computed: {
            ...mapState({
                projectAudits: state => state.projectModule.projectAudits,
                totalProjectAudits: state => state.projectModule.totalProjectAudits
            }),
            ...mapGetters(['isClientUser', 'isPriorAuditor', 'isPeerReviewer']),
            tableHeaders(){
                let headers = []
                headers = Object.assign(headers, this.headers)
                if(this.isClientUser){
                    headers.push({
                        column: 'Status',
                        prop: 'client_status'
                    })
                }
                else if(this.isPriorAuditor){
                    headers.push({
                        column: 'Status',
                        prop: 'prior_auditor_status'
                    })
                }
                else if(this.isPeerReviewer){
                    headers.push({
                        column: 'Status',
                        prop: 'peer_reviewer_status'
                    })
                }
                return headers
            }
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('projectModule/getProjectAudits', {
                page: 1
            })
            this.$store.dispatch('setLoading', false)
        }
    }
</script>