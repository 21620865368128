<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Clients</span>
            <div class="flex space-x-5">
                <Search @search="searchClients" @clear="searchClients" />
                <button class="btn btn-primary" @click="showCreateDialog">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    <span>Create</span>
                </button>
            </div>
        </div>
        <DataTable 
            :headers="headers" 
            :data="clients" 
            :total-items="totalClients" 
            :actions="['view', 'edit', 'delete']" 
            :item="{id: 'client_id', name: 'name'}"
            :view-path="'clients.details'"
            @editItem="showEditDialog" 
            @deleteItem="showDeleteConfirmation"
            @changePage="getClients" />
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue" v-if="!editMode">Create Client</span>
                <span class="font-semibold text-custom-blue" v-else>Update Client</span>
            </template>
            <template v-slot:body>
                <div class="error" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg }}</span>
                </div>
                <div class="flex flex-col mx-5 text-custom-blue space-y-1 mt-2">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Client Name <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('name')" class="text-xs text-red-500">{{ errors.name }}</span>
                        </div>
                        <input v-model="clientForm.name" type="text" class="input-text" :class="{'border-red-500': hasError('name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Client Email <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('email')" class="text-xs text-red-500">{{ errors.email }}</span>
                        </div>
                        <input v-model="clientForm.email" type="text" class="input-text" :class="{'border-red-500': hasError('email') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Contact Number <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('contact_number')" class="text-xs text-red-500">{{ errors.contact_number }}</span>
                        </div>
                        <input v-model="clientForm.contact_number" type="text" class="input-text" :class="{'border-red-500': hasError('contact_number') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Address Line 1 <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('address_line_1')" class="text-xs text-red-500">{{ errors.address_line_1 }}</span>
                        </div>
                        <textarea v-model="clientForm.address_line_1" cols="30" rows="2" autocomplete="nope" :class="{'border-red-500': hasError('address_line_1') }"></textarea>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Address Line 2</span>
                            <span v-if="hasError('address_line_2')" class="text-xs text-red-500">{{ errors.address_line_2 }}</span>
                        </div>
                        <textarea v-model="clientForm.address_line_2" cols="30" rows="2" autocomplete="nope"></textarea>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">City <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('city')" class="text-xs text-red-500">{{ errors.city }}</span>
                        </div>
                        <input v-model="clientForm.city" type="text" class="input-text" autocomplete="nope" :class="{'border-red-500': hasError('city') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">State <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('state')" class="text-xs text-red-500">{{ errors.state }}</span>
                        </div>
                        <input v-model="clientForm.state" type="text" class="input-text" autocomplete="nope" :class="{'border-red-500': hasError('state') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Zip Code <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('zip_code')" class="text-xs text-red-500">{{ errors.zip_code }}</span>
                        </div>
                        <input v-model="clientForm.zip_code" type="text" class="input-text" autocomplete="nope" :class="{'border-red-500': hasError('zip_code') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Type <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('type')" class="text-xs text-red-500">{{ errors.type }}</span>
                        </div>
                        <select v-model="clientForm.type" :class="{'border-red-500': hasError('type') }">
                            <option value="">Choose a type</option>
                            <option :value="type.value" v-for="(type, index) in types" :key="index">{{ type.text }}</option>
                        </select>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="createClient" v-if="!editMode">Add</button>
                <button class="btn-small btn-secondary" @click="updateClient" v-else>Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showConfirmation" @cancel="showConfirmation = false" @deleteItem="deleteClient" :is-strict="true">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete <span class="text-blue-600">[{{ itemToBeDeleted.name }}]</span>? All related to client such as users, projects and audits will be deleted as well.
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import Dialog from '../../../reusables/Dialog'
    import Confirmation from '../../../reusables/Confirmation'
    import Search from '../../../reusables/Search'
    import { mapState } from 'vuex'

    export default {
        components: {
            DataTable,
            Dialog,
            Search,
            Confirmation
        },
        data: () => ({
            showDialog: false,
            showConfirmation: false,
            headers: [
                {
                    column: 'Client ID',
                    prop: 'client_id',
                    customClass: 'w-36'
                },
                {
                    column: 'Client Name',
                    prop: 'name'
                },
                {
                    column: 'Client Email',
                    prop: 'email'
                },
                {
                    column: 'Contact Number',
                    prop: 'contact_number'
                },
                {
                    column: 'Type',
                    prop: 'type_name'
                }
            ],
            clientForm: {
                name: '',
                email: '',
                contact_number: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip_code: '',
                type: ''
            },
            itemToBeDeleted: [],
            editMode: false,
            errors: {},
            validated: false,
            errorMessage: '',
            keyword: '',
            types: [
                {
                    text: 'Non Profit',
                    value: 1
                },
                {
                    text: 'C Corp',
                    value: 2
                },
                {
                    text: 'S Corp',
                    value: 3
                },
                {
                    text: 'LLC-Partnership',
                    value: 4
                },
                {
                    text: 'Partnership',
                    value: 5
                },
                {
                    text: 'Schedule C',
                    value: 6
                },
                {
                    text: 'Individual',
                    value: 7
                },
            ]
        }),
        computed: {
            ...mapState({
                clients: state => state.clientModule.clients,
                totalClients: state => state.clientModule.totalClients
            })
        },
        watch: {
            clientForm: {
                handler(){
                    if(this.validated){
                        this.validate()
                    }
                },
                deep: true
            }
        },
        methods: {
            showCreateDialog(){
                this.editMode = false
                this.validated = false
                this.resetForm()
                this.errorMessage = ''
                this.showDialog = true
            },
            async createClient(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('clientModule/createClient', this.clientForm)
                    .then(response => {
                        if(response === true){
                            this.showDialog = false
                            this.validated = false
                            this.resetForm()
                        }
                        else{
                            this.errorMessage = response
                        }
                    })
                    await this.getClients(1)
                    this.$store.dispatch('setLoading', false)
                }
            },
            async searchClients(keyword){
                this.keyword = keyword
                await this.getClients(1)
            },
            async getClients(page){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('clientModule/getClients', { page: page, keyword: this.keyword })
                this.$store.dispatch('setLoading', false)
            },
            showDeleteConfirmation(client){
                this.itemToBeDeleted = client
                this.showConfirmation = true
            },
            async deleteClient(){
                this.$store.dispatch('setLoading', true)
                this.showConfirmation = false
                await this.$store.dispatch('clientModule/deleteClient', this.itemToBeDeleted.id)
                await this.getClients(1)
                this.itemToBeDeleted = []
                this.$store.dispatch('setLoading', false)
            },
            showEditDialog(client){
                this.editMode = true
                Object.assign(this.clientForm, client) // assign object to remove reactive state
                this.showDialog = true
            },
            async updateClient(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('clientModule/updateClient', this.clientForm)
                    .then(response => {
                        if(response === true){
                            this.showDialog = false
                            this.validated = false
                            this.resetForm()
                        }
                        else{
                            this.errorMessage = response
                        }
                    })
                    await this.getClients(1)
                    this.$store.dispatch('setLoading', false)
                }
            },
            resetForm(){
                this.clientForm = {
                    name: '',
                    email: '',
                    contact_number: '',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    type: ''
                }
            },
            validate(){
                this.errors = {}
                if(!this.clientForm.name){
                    this.errors.name = 'Required'
                }
                if(!this.clientForm.email){
                    this.errors.email = 'Required'
                }
                else{
                    if(!/.+@.+\..+/.test(this.clientForm.email)){
                        this.errors.email = 'Invalid email format'
                    }
                }
                if(!this.clientForm.contact_number){
                    this.errors.contact_number = 'Required'
                }
                if(!this.clientForm.address_line_1){
                    this.errors.address_line_1 = 'Required'
                }
                if(!this.clientForm.city){
                    this.errors.city = 'Required'
                }
                if(!this.clientForm.state){
                    this.errors.state = 'Required'
                }
                if(!this.clientForm.zip_code){
                    this.errors.zip_code = 'Required'
                }
                if(!this.clientForm.type){
                    this.errors.type = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            }
        },
        async mounted(){
            await this.getClients(1)
        }
    }
</script>