<template>
    <div class="flex justify-center items-center">
        <form @submit.prevent="sendResetPassword">
            <div class="flex flex-col space-y-4 border rounded bg-white w-96 h-80 p-8">
                <input v-model="email" type="text" placeholder="Email" class="input-text" :class="{'border-red-500': false }">
                <button class="btn" :class="/.+@.+\..+/.test(email) ? 'btn-secondary' : 'btn-disabled'" type="submit" :disabled="!/.+@.+\..+/.test(email)">Request</button>
                <router-link :to="{ name: 'login' }" class="text-custom-blue cursor-pointer text-sm underline">Back to Login</router-link>
            </div>
        </form>
    </div>
</template>
<script>
    export default {
        data: () => ({
            email: ''
        }),
        methods: {
            async sendResetPassword(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('authModule/sendResetPassword', this.email)
                this.$store.dispatch('setLoading', false)
            }
        }
    }
</script>