<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Users</span>
            <div class="flex space-x-5">
                <Dropdown v-model="filter" :options="['Team', 'Client Users']" :default="'Team'" v-if="isTeam" />
                <Search @search="searchUsers" @clear="searchUsers" />
                <button class="btn btn-primary" @click="showCreateDialog">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    <span>Create</span>
                </button>
            </div>
        </div>
        <DataTable 
            :headers="adminHeaders" 
            :data="admins" 
            :total-items="totalAdmins" 
            :customColumn="'permission'"
            :actions="['view', 'edit', 'delete', 'more']"
            :view-path="'users.details'"
            :item="{id: 'user_id', name: 'full_name'}"
            @editItem="showEditDialog"
            @resendCredentials="resendCredentials"
            @deleteItem="showDeleteConfirmation"
            @showPermission="showPermission"
            @changePage="getUsers"
            v-if="filter == 'Team' && isTeam" />
        <DataTable 
            :headers="userHeaders" 
            :data="users" 
            :total-items="totalUsers" 
            :actions="['edit', 'delete', 'more']" 
            :item="{id: 'user_id', name: 'full_name'}"
            @editItem="showEditDialog"
            @resendCredentials="resendCredentials"
            @deleteItem="showDeleteConfirmation"
            @changePage="getUsers"
            v-else />
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>
                <span class="text-sm">Manage Client Permission: {{  }}</span>
            </template>
            <template v-slot:body>
                <div class="py-2 px-5">
                    <div class="border rounded flex bg-white items-center">
                        <input type="text" class="h-10 pl-3 focus:outline-none flex-grow" placeholder="Search here">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>
                <div class="flex justify-between py-3 bg-gray-200 px-5">
                    <span class="text-gray-400">CLIENT NAME</span>
                    <input type="checkbox" class="w-4 h-4" v-model="checkAll" @change="toggleCheckAll">
                </div>
                <div class="overflow-auto max-h-96 h-3/5">
                    <div v-for="(client, index) in clientList" class="flex justify-between px-5 py-3 border-b" :key="index">
                        <span>{{ client.text }}</span>
                        <input v-model="permission.clients" :value="client.value" type="checkbox" class="w-4 h-4" />
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn btn-secondary" @click="assignClients">Save</button>
            </template>
        </Dialog>
        <Dialog v-if="showUserDialog" @closeDialog="showUserDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue" v-if="!editMode">Create User</span>
                <span class="font-semibold text-custom-blue" v-else>Update User</span>
            </template>
            <template v-slot:body>
                <div class="border rounded my-2 mx-5 px-5 py-2 border-red-500 bg-red-50 text-red-500 text-sm" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg }}</span>
                </div>
                <div class="flex flex-col m-5 text-custom-blue space-y-1">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">First Name</span>
                            <span v-if="hasError('first_name')" class="text-xs text-red-500">{{ errors.first_name }}</span>
                        </div>
                        <input v-model="userForm.first_name" type="text" class="input-text" :class="{'border-red-500': hasError('first_name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Last Name</span>
                            <span v-if="hasError('last_name')" class="text-xs text-red-500">{{ errors.last_name }}</span>
                        </div>
                        <input v-model="userForm.last_name" type="text" class="input-text" :class="{'border-red-500': hasError('last_name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Email</span>
                            <span v-if="hasError('email')" class="text-xs text-red-500">{{ errors.email }}</span>
                        </div>
                        <input v-model="userForm.email" type="text" class="input-text" :class="{'border-red-500': hasError('email') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Role</span>
                            <span v-if="hasError('role_id')" class="text-xs text-red-500">{{ errors.role_id }}</span>
                        </div>
                        <select v-model="userForm.role_id" :class="{'border-red-500': hasError('role_id') }">
                            <option value="">Choose a role</option>
                            <option :value="role.role_id" v-for="(role, index) in roles" :key="index">{{ role.name }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col" v-if="![1,2,3,7].includes(userForm.role_id) && userForm.role_id != ''">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Client</span>
                            <span v-if="hasError('client_id')" class="text-xs text-red-500">{{ errors.client_id }}</span>
                        </div>
                        <div class="rounded" :class="{'border border-red-500': hasError('client_id') }">
                            <model-select
                                :options="clientList"
                                v-model="userForm.client_id[0]"
                            ></model-select>
                        </div>
                    </div>
                    <div class="flex flex-col" v-if="showHourlyRate && (isSuperAdmin || isAdmin)">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Hourly Rate</span>
                            <span v-if="hasError('current_hourly_rate')" class="text-xs text-red-500">{{ errors.current_hourly_rate }}</span>
                        </div>
                        <input v-model="userForm.current_hourly_rate" type="text" class="input-text" :class="{'border-red-500': hasError('current_hourly_rate') }" placeholder="00.00">
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="createUser" v-if="!editMode">Add</button>
                <button class="btn-small btn-secondary" @click="updateUser" v-else>Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showConfirmation" @cancel="showConfirmation = false" @deleteItem="deleteUser" :is-strict="true">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete <span class="text-blue-600">[{{ itemToBeDeleted.name }}]</span>?
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import Dialog from '../../../reusables/Dialog'
    import Search from '../../../reusables/Search'
    import Confirmation from '../../../reusables/Confirmation'
    import Dropdown from '../../../reusables/Dropdown'
    import { ModelSelect } from 'vue-search-select'
    import { mapGetters, mapState } from 'vuex'

    export default {
        components: {
            DataTable,
            Dialog,
            Search,
            Confirmation,
            ModelSelect,
            Dropdown
        },
        data: () => ({
            showDialog: false,
            showUserDialog: false,
            showConfirmation: false,
            permission: {
                clients: [],
                user: 0
            },
            checkAll: false,
            adminHeaders: [
                {
                    column: 'Full Name',
                    prop: 'full_name'
                },
                {
                    column: 'Email',
                    prop: 'email'
                },
                {
                    column: 'Clients',
                    prop: 'client_list'
                },
                {
                    column: 'Type',
                    prop: 'role'
                },
                {
                    column: 'Permission',
                    prop: 'clients'
                }
            ],
            userHeaders: [
                {
                    column: 'Full Name',
                    prop: 'full_name'
                },
                {
                    column: 'Email',
                    prop: 'email'
                },
                {
                    column: 'Client',
                    prop: 'client'
                },
                {
                    column: 'Type',
                    prop: 'role'
                }
            ],
            userForm: {
                first_name: '',
                last_name: '',
                email: '',
                role_id: '',
                client_id: [],
            },
            userFormCopy: {},
            clientId: [],
            hasUserFormChanges: false,
            itemToBeDeleted: [],
            editMode: false,
            errors: {},
            validated: false,
            errorMessage: [],
            filter: 'Team',
            keyword: null,
            page: 1,
            showHourlyRate: false
        }),
        computed: {
            ...mapState({
                totalAdmins: state => state.userModule.totalAdmins,
                totalUsers: state => state.userModule.totalUsers,
                clients: state => state.clientModule.clients,
                roles: state => state.roleModule.roles,
            }),
            ...mapGetters('userModule', ['admins', 'users']),
            ...mapGetters('clientModule', ['clientList']),
            ...mapGetters(['isTeam', 'isSuperAdmin', 'isAdmin'])
        },
        watch: {
            userForm: {
                handler(){
                    // if(this.filter != 'Team'){
                    this.hasUserFormChanges = (this.userForm.first_name != this.userFormCopy.first_name || this.userForm.last_name != this.userFormCopy.last_name || this.userForm.email != this.userFormCopy.email || this.userForm.role_id != this.userFormCopy.role_id) ? true : false
                    this.hasClientChanges = (this.userForm.client_id[0] != this.clientId[0]) ? true : false
                    // }

                    if(this.validated){
                        this.validate()
                    }
                },
                deep: true
            },
            async filter(){
                await this.getUsers(1)
            },
            'userForm.role_id'(val){
                if(this.roles.filter(role => role.role_id == val)[0].group == 'Internal'){
                    this.userForm.client_id = []
                    this.showHourlyRate = true
                }
                else{
                    if(this.roles.filter(role => role.role_id == val)[0].name == 'Peer Reviewer'){
                        this.userForm.client_id = []
                    }
                    this.userForm.current_hourly_rate = null
                    this.showHourlyRate = false
                }
            }
        },
        methods: {
            showPermission({clients, user}){
                this.checkAll = this.clients.length == clients.length
                this.permission.clients = clients.map(client => client.client_id)
                this.permission.user = user
                this.showDialog = true
            },
            async searchUsers(keyword){
                this.keyword = keyword
                await this.getUsers(1)
            },
            async getUsers(page){
                this.$store.dispatch('setLoading', true)
                this.page = page
                if(this.filter == 'Team'){
                    await this.$store.dispatch('userModule/getAdmins', { page: page, keyword: this.keyword })
                }
                else{
                    await this.$store.dispatch('userModule/getClientUsers', { page: page, keyword: this.keyword })
                }
                this.$store.dispatch('setLoading', false)
            },
            async assignClients(){
                await this.$store.dispatch('userModule/assignClients', this.permission)
                this.$store.dispatch('userModule/getAdmins', { page: this.page, keyword: this.keyword })
                this.showDialog = false
            },
            showCreateDialog(){
                this.showUserDialog = true
                this.editMode = false
                this.errorMessage = ''
                this.resetForm()
            },
            async createUser(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('userModule/createUser', this.userForm)
                    .then(response => {
                        if(response === true){
                            this.showUserDialog = false
                            this.validated = false
                        }
                        else{
                            this.errorMessage = response
                        }
                    })
                    this.getUsers(1)
                    this.$store.dispatch('setLoading', false)
                }
            },
            toggleCheckAll(){
                this.permission.clients = this.checkAll ? this.clientList.map(client => client.value) : []
            },
            showEditDialog(user){
                this.errorMessage = ''
                this.resetForm()
                this.editMode = true
                Object.assign(this.userForm, user) // assign object to remove reactive state
                Object.assign(this.userFormCopy, user) //make a copy for changes checking due to api having separate endpoint for changing users' clients
                this.$delete(this.userFormCopy, 'client_id')
                Object.assign(this.clientId, user.client_id) //separated copy of client due to array inside object still being reactive
                this.showUserDialog = true
            },
            async updateUser(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('userModule/updateUser', { 
                        user: this.userForm,
                        hasUserFormChanges: this.hasUserFormChanges,
                        hasClientChanges: this.hasClientChanges
                    })
                    .then(response => {
                        if(response === true){
                            this.showUserDialog = false
                            this.validated = false
                        }
                        else{
                            this.errorMessage = response
                        }
                    })
                    this.getUsers(this.page)
                    this.$store.dispatch('setLoading', false)
                    this.validated = false
                    this.hasUserFormChanges = false
                }
            },
            showDeleteConfirmation(user){
                this.itemToBeDeleted = user
                this.showConfirmation = true
            },
            async deleteUser(){
                this.$store.dispatch('setLoading', true)
                this.showConfirmation = false
                await this.$store.dispatch('userModule/deleteUser', this.itemToBeDeleted.id)
                this.getUsers(1)
                this.itemToBeDeleted = []
                this.$store.dispatch('setLoading', false)
            },
            resetForm(){
                this.userForm = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    role_id: '',
                    client_id: []
                }
            },
            validate(){
                this.errors = {}
                if(!this.userForm.first_name){
                    this.errors.first_name = 'Required'
                }
                if(!this.userForm.last_name){
                    this.errors.last_name = 'Required'
                }
                if(!this.userForm.email){
                    this.errors.email = 'Required'
                }
                else{
                    if(!/.+@.+\..+/.test(this.userForm.email)){
                        this.errors.email = 'Invalid email format'
                    }
                }
                if(!this.userForm.role_id){
                    this.errors.role_id = 'Required'
                }
                if(!this.userForm.client_id.length > 0 && ![1,2,3,4,7].includes(this.userForm.role_id)){
                    this.errors.client_id = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
            async resendCredentials(userId){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('authModule/resendCredentials', userId)
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.getUsers(this.page)
            await this.$store.dispatch('clientModule/getClientList')
            await this.$store.dispatch('roleModule/getRoles')
            this.$store.dispatch('setLoading', false)
        }
    }
</script>