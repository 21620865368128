<template>
    <div class="flex w-full">
        <SideBar v-if="!['login', 'questionnaire', 'resetpassword', 'forgotpassword'].includes($route.name)" />
        <div class="flex-grow h-screen">
            <Header />
            <router-view class="mt-1 px-10 pt-5 h-5/6" :class="{ 'overflow-auto' : ['clients.details', 'profile'].includes($route.name) }"></router-view>
        </div>
        <Loading v-if="isLoading" />
        <Snackbar v-if="snackbar" />
    </div>
</template>

<script>
    import SideBar from './SideBar'
    import Header from './Header'
    import Loading from './reusables/Loading'
    import Snackbar from './reusables/Snackbar'
    import axios from 'axios'
    import { mapState } from 'vuex'
    
    export default {
        components: {
            SideBar,
            Header,
            Loading,
            Snackbar
        },
        data: () => ({
            snackbarMsg: '',
            snackbar: false
        }),
        computed:{
            ...mapState({
                isLoading: state => state.isLoading
            })
        },
        async mounted() {
            if(this.$route.name != null && !['login', 'resetpassword', 'forgotpassword'].includes(this.$route.name)){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('getUserRole')
                this.$store.dispatch('setLoading', false)
            }
        },
        created(){
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('bearerToken')}`
            this.$store.watch(
                state => state.snackbar, 
                (snackbar) => {
                    this.snackbarMsg = snackbar.message
                    this.snackbar = true
                    setTimeout(() => this.snackbar = false, 3000)
                }, 
                {
                    deep: true
                }
            )
        }
    }
</script>
