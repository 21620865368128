import axios from 'axios'

export default {
    namespaced: true,
    state: {
        projects: [],
        totalProjects: 0,
        projectAudits: [],
        totalProjectAudits: 0
    },
    getters: {
        projects(state){
            let projects = state.projects.map(project => ({
                value: project.project_id,
                text: project.name
            }))
            return projects
        }
    },
    mutations: {
        SET_PROJECTS(state, projects){
            state.projects = projects.data
            state.totalProjects = projects.meta.total
        },
        SET_PROJECT_AUDITS(state, projectAudits){
            state.projectAudits = projectAudits.data
            state.totalProjectAudits = projectAudits.meta.total
        },
    },
    actions: {
        async getProjects({commit}, payload){
            let projectEndpoint = this.getters.isSuperAdminOrAdmin ? 'projects' : 'assigned-projects'
            let url = payload.hasOwnProperty('keyword') && payload.keyword != null ? `${this.state.endpoint}/${projectEndpoint}?q=${payload.keyword}&page=${payload.page}` : `${this.state.endpoint}/${projectEndpoint}?page=${payload.page}`
            await axios.get(url)
            .then(response => {
                commit('SET_PROJECTS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getProjectsByClient({commit}, clientId){
            await axios.get(`${this.state.endpoint}/projects?client_id=${clientId}`)
            .then(response => {
                commit('SET_PROJECTS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getProjectAudits({commit}, payload){
            let projectAuditEndpoint = this.getters.isSuperAdminOrAdmin ? 'audits' : 'authenticated-user-audits'
            let url = payload.hasOwnProperty('keyword') && payload.keyword != null ? `${this.state.endpoint}/project/${projectAuditEndpoint}?q=${payload.keyword}&page=${payload.page}` : `${this.state.endpoint}/project/${projectAuditEndpoint}?page=${payload.page}`
            await axios.get(url)
            .then(response => {
                commit('SET_PROJECT_AUDITS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getProjectAuditsByFilter({commit}, payload){
            let projectAuditEndpoint = this.getters.isSuperAdminOrAdmin ? 'audits' : 'authenticated-user-audits'
            await axios.get(`${this.state.endpoint}/project/${projectAuditEndpoint}?client=${payload.client}&year=${payload.year}`)
            .then(response => {
                commit('SET_PROJECT_AUDITS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async createProject({}, project){
            return await axios.post(`${this.state.endpoint}/project`, project)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data.hasOwnProperty('errors')){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async updateProject({}, project){
            return await axios.patch(`${this.state.endpoint}/project/${project.project_id}`, project)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                console.log(err.response)
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.status == 422){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async deleteProject({}, project){
            await axios.delete(`${this.state.endpoint}/project/${project}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async sendAudit({}, payload){
            return await axios.post(`${this.state.endpoint}/project/audit`, payload)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        }
    }
}