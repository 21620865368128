<template>
    <div class="flex flex-shrink-0 flex-col left-0 h-screen bg-custom-blue w-64 z-10">
        <img src="/images/logo2.png" alt="HZ CPA" class="w-24 h-16 mx-auto mt-3 mb-7">
        <template v-for="(menu, index) in menus">
            <router-link :key="index" class="flex text-blue-200 menu hover:active-menu pl-8 h-14 cursor-pointer items-center justify-between" :class="{'active-menu' : $route.name == menu.path }" :to="{name: menu.path}">
                <div class="inline-flex">
                    <img :src="`/images/${menu.icon}`" :alt="menu.name" class="mr-3">
                    <span class="my-auto">{{ menu.name }}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-5 transform transition-transform duration-200 ease-in-out" :class="{'rotate-180': $route.name.includes(menu.path)}" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="menu.hasOwnProperty('submenu')">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </router-link>
            <transition
                enter-active-class="transform transition duration-200 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100" :key="`tr-${index}`" v-if="menu.hasOwnProperty('submenu')">
                    <transition-group v-show="$route.name.includes(menu.path)">
                        <router-link v-for="(submenu, idx) in menu.submenu" :key="`sub-${idx}`" class="flex text-blue-200 menu hover:active-menu pl-12 h-14 cursor-pointer" :class="{'active-menu' : $route.name == submenu.path}" :to="{name: submenu.path}">
                        <!-- <img :src="`/images/${submenu.icon}`" :alt="menu.name" class="mr-3 my-auto"> -->
                            <span class="my-auto text-sm">{{ submenu.name }}</span>
                        </router-link>
                    </transition-group>
            </transition>
        </template>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        data: () => ({
            
        }),
        computed: {
            ...mapGetters(['menus'])
        }
    }
</script>