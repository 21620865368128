<template>
    <div>
        <ProcedureHeader />
        <div class="flex flex-col p-5 space-y-5">
            <model-select
                :options="clientList"
                v-model="clientId"
                :placeholder="'Choose a client'"
            ></model-select>
            <model-select
                :options="projects"
                v-model="projectId"
                :placeholder="'Choose a project'"
                v-if="clientId != ''"
            ></model-select>
            <select v-model="year" v-if="clientId != ''">
                <option value="">Choose a year</option>
                <option v-for="(year, index) in years" :value="year" :key="`year-${index}`">{{ year }}</option>
            </select>
            <div class="inline-flex justify-end">
                <button class="btn btn-primary" @click="showConfirmation = true">Send</button>
            </div>
        </div>
        <OkayCancel v-if="showConfirmation" @cancel="showConfirmation = false" @success="sendAudit">
            <template v-slot:message>
                Would you like to send the audit procedure?
            </template>
        </OkayCancel>
    </div>
</template>
<script>
    import ProcedureHeader from './ProcedureHeader'
    import Question from '../../../reusables/Question'
    import OkayCancel from '../../../reusables/OkayCancel'
    import { ModelSelect } from 'vue-search-select'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            ProcedureHeader,
            Question,
            OkayCancel,
            ModelSelect
        },
        data: () => ({
            showConfirmation: false,
            clientId: '',
            projectId: '',
            year: '',
            errorMessage: ''
        }),
        computed: {
            ...mapGetters('clientModule', ['clientList']),
            ...mapGetters('projectModule', ['projects']),
            years(){
                const date = new Date()
                let year = date.getFullYear()
                let years = []
                while(year >= 2017){
                    years.push(year.toString())
                    year--
                }
                return years
            }
        },
        watch: {
            clientId(val){
                this.$store.dispatch('setLoading', true)
                this.$store.dispatch('projectModule/getProjectsByClient', val)
                this.$store.dispatch('setLoading', false)
            }
        },
        methods: {
            sendAudit(){
                this.showConfirmation = false
                this.$store.dispatch('setLoading', true)
                this.$store.dispatch('projectModule/sendAudit', {
                    client_id: this.clientId,
                    project_id: this.projectId,
                    year: this.year
                })
                .then(response => {
                    if(response !== true){
                        this.errorMessage = response
                    }
                })
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            await this.$store.dispatch('clientModule/getClientList')
        }
    }
</script>