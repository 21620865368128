import axios from 'axios'

export default {
    namespaced: true,
    state: {
        users: [],
        user: [],
        totalUsers: 0,
        admins: [],
        totalAdmins: 0,
        clientId: 0,
        internalUsers: []
    },
    getters: {
        admins(state){
            let admins = []
            state.admins.forEach(admin => {
                let clients = admin.clients.map(client => client.name)
                let excess = 0
                if(clients.length > 2){
                    excess = clients.length-2
                    clients.length = 2
                }
                admins.push({
                    email: admin.email,
                    full_name: admin.full_name,
                    first_name: admin.first_name,
                    last_name: admin.last_name,
                    role_id: admin.role_id,
                    role: admin.role,
                    user_id: admin.user_id,
                    clients: admin.clients,
                    client_list: clients.length ? excess > 0 ? `${clients.join()}...+${excess} more` : clients.join() : '--',
                    current_hourly_rate: admin.current_hourly_rate
                })
            })
            return admins
        },
        users(state){
            let users = []
            state.users.forEach(user => {
                users.push({
                    email: user.email,
                    full_name: user.full_name,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    role_id: user.role_id,
                    role: user.role,
                    user_id: user.user_id,
                    client: user.clients[0]?.name,
                    client_id: [user.clients[0]?.client_id],
                })
            })
            return users
        },
        internalUsersList(state){
            return state.internalUsers.map(user => ({
                value: user.user_id,
                text: user.full_name
            }))
        },
    },
    mutations: {
        SET_USERS(state, users){
            state.users = users.data
            state.totalUsers = users.meta.total
        },
        SET_CLIENT_USERS(state, users){
            state.users = users.data
            state.totalUsers = users.meta.total
            state.clientId = users.client_id
        },
        SET_USER(state, user){
            state.user = user
        },
        SET_ADMINS(state, admins){
            state.admins = admins.data
            state.totalAdmins = admins.meta.total
        },
        SET_INTERNAL_USERS(state, internalUsers){
            state.internalUsers = internalUsers
        },
    },
    actions: {
        async getAdmins({commit}, payload){
            let url = payload.hasOwnProperty('keyword') && payload.keyword != null ? `${this.state.endpoint}/users/admins?q=${payload.keyword}` : `${this.state.endpoint}/users/admins?`

            if(payload.hasOwnProperty('page')){
                url += `&page=${payload.page}`
            }

            await axios.get(url)
            .then(response => {
                commit('SET_ADMINS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getClientUsers({commit}, payload){
            let url = payload.hasOwnProperty('keyword') && payload.keyword != null ? `${this.state.endpoint}/users?page=${payload.page}&q=${payload.keyword}` : `${this.state.endpoint}/users?page=${payload.page}`
            await axios.get(url)
            .then(response => {
                commit('SET_USERS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getInternalUsers({commit}){
            await axios.get(`${this.state.endpoint}/users/internal`)
            .then(response => {
                commit('SET_INTERNAL_USERS', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getAuthenticatedClientUsers({commit}, keyword = null){
            let url = keyword == null ? `${this.state.endpoint}/users/authenticated-client` : `${this.state.endpoint}/users/authenticated-client?q=${keyword}`
            await axios.get(url)
            .then(response => {
                commit('SET_CLIENT_USERS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        getUser({commit}, userId){
            axios.get(`${this.state.endpoint}/user/${userId}`)
            .then(response => {
                commit('SET_USER', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async assignClients({}, payload){
            await axios.post(`${this.state.endpoint}/user/${payload.user}`, { clients: payload.clients })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async createUser({}, user){
            return await axios.post(`${this.state.endpoint}/user`, user)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async updateUser({}, payload){
            //updating of client assigned to user (for client users)
            if(payload.hasClientChanges){
                await axios.post(`${this.state.endpoint}/user/${payload.user.user_id}`, {
                    clients: payload.user.client_id
                })
                .then(response => {
                    this.commit('SET_SNACKBAR', {
                        message: response.data.message,
                        color: 'green'
                    })
                })
                .catch(err => {
                    if(err.response.status == 401){
                        this.dispatch('redirectToLogin')
                    }
                    if(err.response.data){
                        return this.dispatch('extractErrorMessage', err.response.data)
                    }
                })
            }

            //updating of user info
            if(payload.hasUserFormChanges){
                return await axios.patch(`${this.state.endpoint}/user/${payload.user.user_id}`, payload.user)
                .then(response => {
                    this.commit('SET_SNACKBAR', {
                        message: response.data.message,
                        color: 'green'
                    })
                    return true
                })
                .catch(err => {
                    if(err.response.status == 401){
                        this.dispatch('redirectToLogin')
                    }
                    if(err.response.data){
                        return this.dispatch('extractErrorMessage', err.response.data)
                    }
                })
            }

            return true
        },
        async deleteUser({}, user){
            await axios.delete(`${this.state.endpoint}/user/${user}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async updateHourlyRate({}, user){
            await axios.patch(`${this.state.endpoint}/user/change-hourly-rate`, {
                hourly_rate: user.hourly_rate
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        }
    }
}