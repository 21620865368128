<template>
    <div>
        <ProcedureHeader />
    </div>
</template>
<script>
    import ProcedureHeader from './ProcedureHeader'
    export default {
        components: {
            ProcedureHeader
        }
    }
</script>