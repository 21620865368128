<template>
    <div>
        <div class="flex justify-between border-b items-center px-5 py-3">
            <div class="inline-flex justify-center items-center space-x-3">
                <span class="text-sm text-custom-blue font-semibold">{{ step.step }}</span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-custom-blue font-semibold" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-custom-blue font-semibold" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg> -->
            </div>
            <div v-if="$route.name != 'auditprocedures.clientinfo' && isSuperAdmin">
                <button class="btn-small btn-primary" @click="showDialog = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    Add Questionnaire
                </button>
            </div>
        </div>
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>
                <span class="font-semibold">Questionnaire</span>
            </template>
            <template v-slot:body>
                <div class="m-5 text-custom-blue">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Question</span>
                            <span v-if="hasError('question')" class="text-xs text-red-500">{{ errors.question }}</span>
                        </div>
                        <textarea v-model="questionForm.question" cols="30" rows="5" placeholder="Enter question" :class="{'border-red-500': hasError('question') }"></textarea>
                    </div>
                    <div class="flex flex-col mt-3">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Answer Type</span>
                            <span v-if="hasError('type')" class="text-xs text-red-500">{{ errors.type }}</span>
                        </div>
                        <div class="inline-flex justify-left items-center space-x-2 p-2" :class="{'border-red-500 border rounded': hasError('type') }">
                            <div>
                                <input v-model="questionForm.type" type="radio" id="yesno" value="1" name="type">
                                <label for="yesno">Yes/No</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="file" value="2" name="type">
                                <label for="file">File Upload</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="text" value="3" name="type">
                                <label for="text">Text</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="url" value="4" name="type">
                                <label for="url">URL</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex items-center space-x-2">
                            <input type="checkbox" v-model="questionForm.is_required" id="required">
                            <label for="required" class="font-semibold">Is required?</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="createQuestion(false)">Save &amp; Add New</button>
                <button class="btn-small btn-tertiary" @click="createQuestion(true)">Save &amp; Exit</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import Dialog from '../../../reusables/Dialog'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Dialog
        },
        data: () => ({
            showDialog: false,
            step: [],
            steps: [
                {
                    procedure_step_id: null,
                    route: 'auditprocedures.clientinfo',
                    step: 'Client Info'
                },
                {
                    procedure_step_id: 1,
                    route: 'auditprocedures.priorauditorresponse',
                    step: 'Prior Auditor Response'
                },
                {
                    procedure_step_id: 2,
                    route: 'auditprocedures.fraudrisk',
                    step: 'Fraud Risk'
                },
                {
                    procedure_step_id: 3,
                    route: 'auditprocedures.internalcontrol',
                    step: 'Internal Control'
                },
                {
                    procedure_step_id: 4,
                    route: 'auditprocedures.documentrequest',
                    step: 'Document Request'
                },
                {
                    procedure_step_id: 5,
                    route: 'auditprocedures.threemonthreview',
                    step: '3 Month Review'
                },
                {
                    procedure_step_id: 6,
                    route: 'auditprocedures.fourthtotwelfthmonthreview',
                    step: '4 - 12 Month Review'
                },
                {
                    procedure_step_id: 7,
                    route: 'auditprocedures.yearendreview',
                    step: 'Year End Review'
                },
                {
                    procedure_step_id: 8,
                    route: 'auditprocedures.peerreview',
                    step: 'Peer Review'
                }
            ],
            questionForm: {
                question: '',
                type: '',
                is_required: 0
            },
            errors: {},
            validated: false,
            errorMessage: ''
        }),
        computed: {
            ...mapGetters(['isSuperAdmin'])
        },
        watch: {
            questionForm: {
                handler(){
                    if(this.validated){
                        this.validate()
                    }
                },
                deep: true
            }
        },
        methods: {
            async createQuestion(closeAfterSubmit){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('auditProcedureModule/createQuestion', {
                        ...this.questionForm,
                        procedure_step_id: this.step.procedure_step_id
                    })
                    .then(response => {
                        this.questionForm = {
                            question: '',
                            type: '',
                            is_required: 0
                        }
                        this.showDialog = !closeAfterSubmit
                    })
                    .catch(err => {
                        
                    })
                    this.$store.dispatch('auditProcedureModule/getProcedures')
                    this.$store.dispatch('setLoading', false)
                }
            },
            validate(){
                this.errors = {}
                if(!this.questionForm.question){
                    this.errors.question = 'Required'
                }
                if(!this.questionForm.type){
                    this.errors.type = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            }
        },
        mounted(){
            this.step = this.steps.filter(step => step.route == this.$route.name )[0]
        }
    }
</script>