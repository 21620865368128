<template>
    <div class="inline-flex items-center">
        <span class="text-sm mr-2 font-semibold text-custom-blue" v-if="label != ''">{{ label }}</span>
        <div class="relative flex flex-col px-3 rounded max-w-xs w-40 bg-white" :class="{ 'border border-gray-200 h-10': hasBorder }" @click="showOptions = !showOptions">
            <div class="flex justify-between my-auto">
                <span class="my-auto font-semibold truncate" :class="isAudit ? auditStatusColor[selected.text.toLowerCase()] : ''"><template v-if="typeof selected == 'string'">{{ selected }}</template><template v-else>{{ selected.text }}</template></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto transform transition-transform duration-200 ease-in-out" :class="{'rotate-180': showOptions}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            <transition
                enter-active-class="transform transition duration-300 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100">
                <div v-show="showOptions" class="absolute flex flex-col shadow bg-white w-40 h-auto max-h-72 rounded border-gray-300 top-11 -left-0 overflow-auto z-10" :class="{'-translate-y-full': !showOptions}">
                    <div v-for="(option, index) in options" :key="index" class="py-4 pl-5 pr-3 border-b inline-flex justify-between cursor-pointer hover:bg-gray-100 text-sm" @click="setItem(option)">
                        <span v-if="typeof option == 'string'">{{ option }}</span>
                        <span v-else>{{ option.text }}</span>
                        <template v-if="option == selected">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            hasBorder: {
                default: true,
                type: Boolean
            },
            textColor: {
                default: 'text-black',
                type: String
            },
            options: {
                type: Array
            },
            default: {
                default: '',
                type: [String, Object]
            },
            isAudit: {
                default: false,
                type: Boolean
            },
            label: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            showOptions: false,
            selected: {
                text: '',
                value: ''
            },
            auditStatusColor: {
                pending: 'text-yellow-600',
                prepared: 'text-blue-400',
                reviewed: 'text-green-500'
            }
        }),
        methods: {
            setItem(item){
                this.selected = item
                this.$emit('input', typeof item == 'string' ? item : item.value)
            },
            close(e){
                if (!this.$el.contains(e.target)) {
                    this.showOptions = false
                }
            }
        },
        mounted(){
            this.selected.text = this.default
            document.addEventListener('click', this.close)
        },
        beforeDestroy(){
            document.removeEventListener('click', this.close)
        }
    }
</script>