import axios from 'axios'

export default {
    namespaced: true,
    state: {
        milestone: []
    },
    getters: {
        
    },
    mutations: {
        SET_PROJECT_MILESTONE(state, milestone){
            state.milestone = milestone
        }
    },
    actions: {
        async getMilestone({commit}, year){
            await axios.get(`${this.state.endpoint}/project/milestone/${year}`)
            .then(response => {
                commit('SET_PROJECT_MILESTONE', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async update({}, payload){
            return await axios.patch(`${this.state.endpoint}/project/milestone/${payload.id}`, payload)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        }
    }
}