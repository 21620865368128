<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Projects</span>
            <div class="flex space-x-5">
                <Search @search="searchProjects" @clear="searchProjects" :placeholder="'Search by project name'" />
                <button class="btn btn-primary" @click="showCreateDialog">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    <span>Create</span>
                </button>
            </div>
        </div>
        <DataTable 
            :headers="headers" 
            :data="projects" 
            :total-items="totalProjects"
            :actions="['view', 'edit', 'delete']" 
            :item="{id: 'project_id', name: 'name'}"
            :view-path="'projects.details'"
            @editItem="showEditDialog" 
            @deleteItem="showDeleteConfirmation"
            @changePage="getProjects" />
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue" v-if="!editMode">Create Project</span>
                <span class="font-semibold text-custom-blue" v-else>Update Project</span>
            </template>
            <template v-slot:body>
                <div class="border rounded my-2 mx-5 px-5 py-2 border-red-500 bg-red-50 text-red-500 text-sm" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg }}</span>
                </div>
                <div class="flex flex-col m-5 text-custom-blue space-y-1">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Project Name</span>
                            <span v-if="hasError('name')" class="text-xs text-red-500">{{ errors.name }}</span>
                        </div>
                        <input v-model="projectForm.name" type="text" class="input-text" :class="{'border-red-500': hasError('name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Client</span>
                            <span v-if="hasError('client_id')" class="text-xs text-red-500">{{ errors.client_id }}</span>
                        </div>
                        <div class="rounded" :class="{'border border-red-500': hasError('client_id') }">
                            <model-select
                                :options="clientList"
                                v-model="projectForm.client_id"
                            ></model-select>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Year</span>
                            <span v-if="hasError('year')" class="text-xs text-red-500">{{ errors.year }}</span>
                        </div>
                        <select v-model="projectForm.year" :class="{'border-red-500': hasError('year') }">
                            <option :value="year" v-for="(year, index) in years" :key="index">{{ year }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Type</span>
                            <span v-if="hasError('type')" class="text-xs text-red-500">{{ errors.type }}</span>
                        </div>
                        <select v-model="projectForm.type" :class="{'border-red-500': hasError('type') }">
                            <option value="1">Audit</option>
                            <option value="2">Tax</option>
                        </select>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="createProject" v-if="!editMode">Add</button>
                <button class="btn-small btn-secondary" @click="updateProject" v-else>Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showConfirmation" @cancel="showConfirmation = false" @deleteItem="deleteProject" :is-strict="true">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete <span class="text-blue-600">[{{ itemToBeDeleted.name }}]</span>? All audits with this project will be deleted as well.
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import Search from '../../../reusables/Search'
    import Dialog from '../../../reusables/Dialog'
    import Confirmation from '../../../reusables/Confirmation'
    import { ModelSelect } from 'vue-search-select'
    import { mapState, mapGetters } from 'vuex'

    export default {
        components: {
            DataTable,
            Search,
            Dialog,
            Confirmation,
            ModelSelect
        },
        data: () => ({
            headers: [
                {
                    column: 'Project Name',
                    prop: 'name'
                },
                {
                    column: 'project id',
                    prop: 'project_id',
                    customClass: 'w-40'
                },
                {
                    column: 'Client Name',
                    prop: 'client_name'
                },
                {
                    column: 'Year',
                    prop: 'year'
                },
                {
                    column: 'Type',
                    prop: 'type_name'
                }
            ],
            showDialog: false,
            projectForm: {
                name: '',
                client_id: '',
                year: '',
                type: ''
            },
            showConfirmation: false,
            itemToBeDeleted: [],
            editMode: false,
            errors: {},
            validated: false,
            errorMessage: '',
            keyword: null,
            page: 1
        }),
        computed: {
            ...mapState({
                projects: state => state.projectModule.projects,
                totalProjects: state => state.projectModule.totalProjects
            }),
            ...mapGetters('clientModule', ['clientList'])
        },
        watch: {
            projectForm: {
                handler(){
                    if(this.validated){
                        this.validate()
                    }
                },
                deep: true
            }
        },
        methods: {
            async searchProjects(keyword){
                this.keyword = keyword
                await this.getProjects(1)
            },
            showCreateDialog(){
                this.editMode = false
                this.resetForm()
                this.showDialog = true
                this.errorMessage = ''
            },
            async createProject(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('projectModule/createProject', this.projectForm)
                    .then(response => {
                        if(response === true){
                            this.showDialog = false
                            this.errorMessage = ''
                        }
                        else{
                            this.errorMessage = response
                        }
                    })
                    await this.getProjects(1)
                    this.$store.dispatch('setLoading', false)
                }
                this.validated = false
            },
            showEditDialog(project){
                this.editMode = true
                Object.assign(this.projectForm, project) // assign object to remove reactive state
                this.showDialog = true
            },
            async updateProject(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('projectModule/updateProject', this.projectForm)
                    .then(response => {
                        if(response === true){
                            this.showDialog = false
                            this.validated = false
                            this.errorMessage = ''
                        }
                        else{
                            this.errorMessage = response
                        }
                        this.$store.dispatch('setLoading', false)
                    })
                    await this.getProjects(this.page)
                }
            },
            showDeleteConfirmation(project){
                this.itemToBeDeleted = project
                this.showConfirmation = true
            },
            async deleteProject(){
                this.$store.dispatch('setLoading', true)
                this.showConfirmation = false
                await this.$store.dispatch('projectModule/deleteProject', this.itemToBeDeleted.id)
                await this.getProjects(1)
                this.itemToBeDeleted = []
                this.$store.dispatch('setLoading', false)
            },
            resetForm(){
                this.projectForm = {
                    name: '',
                    client_id: '',
                    year: '',
                    type: ''
                }
            },
            validate(){
                this.errors = {}
                if(!this.projectForm.name){
                    this.errors.name = 'Required'
                }
                if(!this.projectForm.client_id){
                    this.errors.client_id = 'Required'
                }
                if(!this.projectForm.year){
                    this.errors.year = 'Required'
                }
                if(!this.projectForm.type){
                    this.errors.type = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
            async getProjects(page){
                this.page = page
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectModule/getProjects', { page: page, keyword: this.keyword })
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            await this.getProjects(1)
            await this.$store.dispatch('clientModule/getClientList')
        }
    }
</script>