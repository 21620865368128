<template>
    <div>
        <div class="flex justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Milestone</span>
            <div class="flex space-x-5">
                <Dropdown v-model="year" :options="years" :default="defaultYear.getFullYear().toString()" />
            </div>
        </div>
        <div class="mt-2 overflow-x-auto w-272 2xl:w-324 3xl:w-352">
            <table class="table-fixed inline-table border w-full">
                <thead class="bg-custom-blue">
                    <tr class="text-sm text-white">
                        <th class="w-6"></th>
                        <th class="p-1 text-center w-36">Client</th>
                        <th class="p-1 text-center w-24">Type</th>
                        <th class="p-1 text-center w-32">Project Name</th>
                        <th class="p-1 text-center w-20">Project</th>
                        <th class="p-1 text-center w-16">Project Manager</th>
                        <th class="p-1 text-center w-16">Year End</th>
                        <th class="p-1 text-center w-16">Due Date</th>
                        <th class="p-1 text-center w-16">Finish Date</th>
                        <th class="p-1 text-center w-5">Ext. Filed?</th>
                        <th class="p-1 text-center w-20">Ext. Due Date</th>
                        <th class="p-1 text-center w-16">Ready to Review</th>
                        <th class="p-1 text-center w-16">Ready to Submit</th>
                        <th class="p-1 text-center w-12">On Going</th>
                        <th class="p-1 text-center w-32">Comment</th>
                        <th class="p-1 text-center w-16">File Status</th>
                    </tr>
                </thead>
                <tbody v-if="milestone.length">
                    <tr v-for="(row, index) in milestone" :key="`ms-${index}`" class="text-xs hover:bg-gray-300 border-b-2">
                        <td class="p-1 text-left">
                            <button class="has-tooltip" @click="showEditForm(row)">
                                <span class="tooltip">Edit</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </button>
                        </td>
                        <td class="p-1 text-left">{{ row.client_name }}</td>
                        <td class="p-1 text-left">{{ row.client_type }}</td>
                        <td class="p-1 text-left">{{ row.project_name }}</td>
                        <td class="p-1 text-left">{{ row.project }}</td>
                        <td class="p-1 text-left">
                            <div class="flex flex-col items-center justify-center">
                                <span v-for="(manager, idx) in row.project_managers" :key="`pm-${idx}`">{{ manager.first_name }}</span>
                            </div>
                        </td>
                        <td class="p-1 text-left">{{ row.year_end | date }}</td>
                        <td class="p-1 text-left">{{ row.due_date | date }}</td>
                        <td class="p-1 text-left">{{ row.finish_date | date }}</td>
                        <td class="p-1 text-left">{{ row.extension | yesNo }}</td>
                        <td class="p-1 text-left">{{ row.extended_due_date | date }}</td>
                        <td class="p-1 text-left">{{ row.ready_to_review }}</td>
                        <td class="p-1 text-left">{{ row.ready_to_submit }}</td>
                        <td class="p-1 text-left">{{ row.ongoing | yesNo }}</td>
                        <td class="p-1 text-left">{{ row.comment }}</td>
                        <td class="p-1 text-left">{{ row.file_status }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="14" class="text-center text-gray-400 p-3">
                            No Data Available
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Dialog v-if="showEditDialog" @closeDialog="showEditDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue">Update</span>
            </template>
            <template v-slot:bodyfull>
                <div class="error" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg }}</span>
                </div>
                <div class="flex flex-col mx-5 text-custom-blue space-y-1 mt-2">
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Client</span>
                            </div>
                            <input v-model="milestoneForm.client_name" type="text" class="input-text" disabled>
                        </div>
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Type</span>
                            </div>
                            <input v-model="milestoneForm.client_type" type="text" class="input-text" disabled>
                        </div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Project Name</span>
                            </div>
                            <input v-model="milestoneForm.project_name" type="text" class="input-text" disabled>
                        </div>
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Project</span>
                            </div>
                            <input v-model="milestoneForm.project" type="text" class="input-text">
                        </div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Year End</span>
                            </div>
                            <date-picker v-model="milestoneForm.year_end" valueType="YYYY-MM-DD" format="YYYY-MM-DD" />
                        </div>
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Due Date</span>
                            </div>
                            <date-picker v-model="milestoneForm.due_date" valueType="YYYY-MM-DD" format="YYYY-MM-DD" />
                        </div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Finish Date</span>
                            </div>
                            <date-picker v-model="milestoneForm.finish_date" valueType="YYYY-MM-DD" format="YYYY-MM-DD" />
                        </div>
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Extended Due Date</span>
                            </div>
                            <date-picker v-model="milestoneForm.extended_due_date" valueType="YYYY-MM-DD" format="YYYY-MM-DD" />
                        </div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Extension Filed?</span>
                            </div>
                            <select v-model="milestoneForm.extension">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">On Going</span>
                            </div>
                            <select v-model="milestoneForm.ongoing">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Ready to Review</span>
                            </div>
                            <input v-model="milestoneForm.ready_to_review" type="text" class="input-text" autocomplete="nope">
                        </div>
                        <div class="flex flex-col w-full">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Ready to Submit</span>
                            </div>
                            <input v-model="milestoneForm.ready_to_submit" type="text" class="input-text" autocomplete="nope">
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Comment</span>
                        </div>
                        <textarea rows="3" cols="40" v-model="milestoneForm.comment" autocomplete="nope" />
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">File Status</span>
                        </div>
                        <input v-model="milestoneForm.file_status" type="text" class="input-text" autocomplete="nope">
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="update">Update</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import Dropdown from '../../../reusables/Dropdown'
    import Dialog from '../../../reusables/Dialog'
    import { mapState } from 'vuex'
    import dayjs from 'dayjs'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    
    export default {
        components: {
            Dropdown,
            Dialog,
            DatePicker
        },
        data: () => ({
            defaultYear: new Date(),
            year: '',
            showEditDialog: false,
            errorMessage: '',
            milestoneForm: {}
        }),
        filters: {
            yesNo(val){
                return val ? 'Yes' : 'No'
            },
            date(val){
                if(val){
                    return dayjs(val).format('MM/DD/YY')
                }
            }
        },
        computed: {
            years(){
                const date = new Date()
                let years = []
                let year = date.getFullYear()
                while(year >= 2017){
                    years.push(year.toString())
                    year--
                }
                return years
            },
            ...mapState({
                milestone: state => state.projectMilestoneModule.milestone
            })
        },
        watch: {
            year(val){
                this.getMilestone(val)
            }
        },
        methods: {
            async getMilestone(year){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectMilestoneModule/getMilestone', year)
                this.$store.dispatch('setLoading', false)
            },
            showEditForm(milestone){
                this.milestoneForm = milestone
                this.showEditDialog = true
            },
            async update(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectMilestoneModule/update', this.milestoneForm)
                this.showEditDialog = false
                this.$store.dispatch('setLoading', false)
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            }
        },
        async mounted(){
            const date = new Date()
            await this.getMilestone(date.getFullYear().toString())
        }
    }
</script>