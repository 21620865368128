<template>
    <div>
        <div class="flex bg-white rounded justify-evenly">
            <div v-for="(step, index) in steps" :key="index" class="inline-flex px-3 py-4 items-center border-r justify-center flex-grow relative" :class="{'active-client-step' : step.status == 'active'}">
                <div class="flex flex-col">
                    <span class="badge-sm absolute top-1" :class="step.group == 'Internal' ? 'text-green-500' : 'text-yellow-500'" v-if="isTeam && step.hasOwnProperty('group')">{{ step.group }}</span>
                    <span class="text-xs mr-2">{{ step.name }}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="step.status != 'done'">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            activeStep: {
                type: String
            },
            steps: {
                type: Array
            }
        },
        data: () => ({
            
        }),
        computed: {
            ...mapGetters('clientAuditProcedureModule', ['clientSteps']),
            ...mapGetters(['isTeam']),
        },
    }
</script>