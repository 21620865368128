<template>
    <div class="flex justify-center items-center">
        <form @submit.prevent="authenticate">
            <div class="flex flex-col space-y-4 border rounded bg-white w-96 h-80 p-8">
                <div class="border rounded px-5 py-2 border-red-500 bg-red-50 text-red-500 text-sm" v-if="errorMessage">
                    <span>{{ errorMessage }}</span>
                </div>
                    <input v-model="loginForm.email" type="text" placeholder="Email" class="input-text" :class="{'border-red-500': hasError('email') }">
                    <div class="inline-flex justify-between items-center h-10 px-3 focus:outline-none border rounded w-full" :class="{'border-red-500': hasError('password') }">
                        <input class="w-full focus:outline-none" v-model="loginForm.password" :type="type" placeholder="Password">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @mousedown="type = 'text'" @mouseup="type = 'password'" v-if="loginForm.password != ''">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </div>
                    <button class="btn btn-secondary" type="submit">Login</button>
                <router-link :to="{ name: 'forgotpassword' }" class="text-custom-blue cursor-pointer text-sm underline">Forgot Password?</router-link>
            </div>
        </form>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'

    export default {
        data: () => ({
            loginForm: {
                email: '',
                password: ''
            },
            errorMessage: '',
            errors: {},
            type: 'password'
        }),
        computed: {
            ...mapGetters(['isTeam', 'isPriorAuditor', 'isPeerReviewer'])
        },
        methods: {
            authenticate(){
                this.validate()
                if(Object.keys(this.errors).length == 0){
                    axios.post('/api/v1/authenticate', this.loginForm)
                    .then(response => {
                        if(response.status == 200){
                            localStorage.setItem('bearerToken', response.data.access_token)
                            this.$store.dispatch('setUser', {
                                id: response.data.id,
                                full_name: response.data.full_name,
                                role: response.data.role,
                                hourly_rate: response.data.hourly_rate
                            })
                            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
                            if(this.isTeam){
                                this.$router.push({ name: 'clients' })
                            }
                            else if(this.isPeerReviewer || this.isPriorAuditor){
                                this.$router.push({ name: 'questionnairelist' })
                            }
                            else{
                                this.$router.push({ name: 'profile' })
                            }
                        }
                    })
                    .catch(err => {
                        this.errorMessage = err.response.data.message
                    })
                }
            },
            validate(){
                this.errors = {}
                if(!this.loginForm.email){
                    this.errors.email = 'Required'
                }
                if(!this.loginForm.password){
                    this.errors.password = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            }
        }
    }
</script>