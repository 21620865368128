<template>
    <div class="my-5">
        <div class="shadow w-full bg-white rounded">
            <table class="table-auto w-full">
                <thead class="border-b">
                    <tr class="text-gray-400 text-sm font-semibold">
                        <th class="px-8 py-2" v-for="(header, index) in headers" :key="`header-${index}`" :class="header.hasOwnProperty('customClass') ? header.customClass : ''">{{ header.column | upperCase }}</th>
                        <th class="px-8 py-2" v-if="actions.length">ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="data.length != 0">
                        <tr v-for="(row, index) in data" :key="`row-${index}`" class="hover:bg-gray-100 border-b">
                            <td v-for="(property, idx) in properties" :key="`prop-${idx}`" class="px-5 py-1">
                                <template v-if="customColumn == 'permission' && idx == 4">
                                    <div  class="text-sm underline text-blue-800 cursor-pointer" @click="$emit('showPermission', { clients: row[property], user: row.user_id })">Manage</div>
                                </template>
                                <!-- <template v-else-if="customColumn == 'status' && idx == 4">
                                    <Dropdown :has-border="false" :key="`drop-${idx}`" :is-audit="true" :options="options" :default="row[property]"></Dropdown>
                                </template> -->
                                <template v-else>
                                    <span class="text-sm">{{ row[property] }}</span>
                                </template>
                            </td>
                            <td v-if="actions.length" class="w-full h-full pr-5 py-3 inline-flex justify-end items-center">
                                <div class="inline-flex space-x-5">
                                    <router-link v-if="actions.includes('view')" :to="{name: viewPath, params: {id: row[item.id]}}" class="has-tooltip my-auto">
                                        <span class="tooltip">View</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </router-link>
                                    <button v-if="actions.includes('edit')" @click="$emit('editItem', row)" class="has-tooltip">
                                        <span class="tooltip">Edit</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </button>
                                    <button v-if="actions.includes('delete') && !actions.includes('more')" @click="deleteItem(row[item.id], row[item.name])" class="has-tooltip">
                                        <span class="tooltip">Delete</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                    <div class="relative flex justify-center">
                                        <button v-if="actions.includes('more')" class="has-tooltip" @click="showMoreDropDown(index)">
                                            <span class="tooltip">More</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                            </svg>
                                        </button>
                                        <transition
                                            enter-active-class="transform transition duration-200 ease-custom"
                                            enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                                            enter-to-class="translate-y-0 scale-y-100 opacity-100">
                                            <div class="absolute top-7 right-0 bg-white border w-40 rounded shadow z-50" v-if="dropDown[index]">
                                                <ul class="text-gray-400">
                                                    <li class="text-sm hover:bg-gray-200 p-2 cursor-pointer inline-flex items-center space-x-2 w-full" @click="resendCredentials(row[item.id])">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                        </svg>
                                                        <span class="text-xs">Resend Credentials</span>
                                                    </li>
                                                    <li class="text-sm hover:bg-gray-200 p-2 cursor-pointer inline-flex items-center space-x-2 w-full" @click="deleteItem(row[item.id], row[item.name])">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                        <span class="text-xs">Delete</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </transition>
                                    </div>
                                    <router-link target="_blank" :to="{ name: externalLinkPath, params: {id: row[item.id]} }" v-if="actions.includes('externalLink')" class="has-tooltip">
                                        <span class="tooltip">Link</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td class="text-sm p-3 text-gray-400 text-center" :colspan="headers.length">No Data Available</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="mt-3 flex justify-between h-12 rounded shadow bg-white" v-if="data.length && hasPagination">
            <span class="text-sm text-gray-500 my-auto ml-5">
                Showing {{ data.length }} item<template v-if="totalItems > 1">s</template> out of {{ totalItems }} result<template v-if="totalItems > 1">s</template>
            </span>
            <!-- pagination -->
            <div class="my-auto mr-5 inline-flex space-x-2">
                <button class="items-center inline-flex space-x-1 px-2 " :class="activePage == 1 ? 'text-gray-300' : 'text-black hover:active-page'" @click="changePage(activePage-1)" :disabled="activePage == 1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    <span class="text-sm">Prev</span>
                </button>
                <button v-for="page in displayedPages" class="text-sm px-1 py-1" :class="page == activePage ? 'active-page' : 'inactive-page hover:active-page'" :key="page" @click="changePage(page)">{{ page }}</button>
                <button class="items-center inline-flex space-x-1 px-2" :class="activePage == totalPages ? 'text-gray-300' : 'text-black hover:active-page'" @click="changePage(activePage+1)" :disabled="activePage == totalPages">
                    <span class="text-sm">Next</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
            <!-- end of pagination -->
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            headers: {
                type: Array,
                required: true
            },
            data: {
                default: () => [],
                type: Array,
                required: true
            },
            totalItems: {
                type: Number,
                default: 0
            }, 
            customColumn: {
                default: '',
                type: String
            },
            options: {
                type: Array,
                required: false
            },
            actions: {
                type: Array,
                default: () => []
            },
            item: {
                type: Object
            },
            viewPath: {
                type: String
            },
            externalLinkPath: {
                type: String
            },
            hasPagination: {
                type: Boolean,
                default: true
            }
        },
        filters: {
            upperCase(val){
                return val.toUpperCase()
            }
        },
        data: () => ({
            activePage: 1,
            rowsPerPage: 10,
            dropDown: []
        }),
        computed: {
            properties(){
                return this.headers.map((header) => header.prop)
            },
            totalPages(){
                return Math.ceil(this.totalItems/this.rowsPerPage)
            },
            noOfPageDisplayed(){
                return this.totalPages < 10 ? this.totalPages : 10
            },
            displayedPages(){
                let pages = []
                let excess = this.activePage+4 > this.totalPages ? (this.activePage+4)-this.totalPages : 0
                let start = this.activePage > 6 ? this.activePage-5 : 1
                start = start-excess < 0 ? 1 : start-excess

                for(let i = 0; i < this.noOfPageDisplayed; i++){
                    pages.push(start+i)
                }
                
                return pages
            }
        },
        methods: {
            changePage(page){
                this.activePage = page
                this.$emit('changePage', page)
            },
            showMoreDropDown(index){
                this.dropDown = []
                for(let i = 0; i < this.data.length; i++){
                    this.dropDown.push(index == i ? !this.dropDown[i] : false)
                }
            },
            deleteItem(id, name){
                this.dropDown = []
                this.$emit('deleteItem', {id: id, name: name})
            },
            resendCredentials(id){
                this.dropDown = []
                this.$emit('resendCredentials', id)
            },
            close(e){
                if (!this.$el.contains(e.target)) {
                    this.dropDown = []
                }
            }
        },
        mounted(){
            document.addEventListener('click', this.close)
        },
        beforeDestroy(){
            document.removeEventListener('click', this.close)
        }
    }
</script>