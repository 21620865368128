<template>
    <div>
        <ProcedureHeader />
        <template v-if="internalControl">
            <div v-for="(question, idx) in internalControl.questions" :key="`q-${idx}`" class="border-b">
                <Question :content="question" :is-super-admin-view="isSuperAdmin" />
            </div>
        </template>
    </div>
</template>
<script>
    import ProcedureHeader from './ProcedureHeader'
    import Question from '../../../reusables/Question'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            ProcedureHeader,
            Question
        },
        data: () => ({

        }),
        computed: {
            ...mapGetters('auditProcedureModule', ['internalControl']),
            ...mapGetters(['isSuperAdmin'])
        }
    }
</script>