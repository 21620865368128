<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Profile</span>
        </div>
        <div class="flex flex-col border bg-white rounded mt-5">
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Client Name</span>
                <span class="ml-5 col-span-3">{{ client.name }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Client Email</span>
                <span class="ml-5 col-span-3">{{ client.email }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Contact Number</span>
                <span class="ml-5 col-span-3">{{ client.contact_number }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Address Line 1</span>
                <span class="ml-5 col-span-3">{{ client.address_line_1 }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Address Line 2</span>
                <span class="ml-5 col-span-3">
                    <template v-if="client.address_line_2 != ''">
                        {{ client.address_line_2 }}
                    </template>
                    <template v-else>-</template>
                </span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">City</span>
                <span class="ml-5 col-span-3">{{ client.city }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">State</span>
                <span class="ml-5 col-span-3">{{ client.state }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Zip Code</span>
                <span class="ml-5 col-span-3">{{ client.zip_code }}</span>
            </div>
        </div>
        <div class="flex w-full justify-start my-5">
            <span class="my-auto text-lg font-bold text-custom-blue">Projects</span>
        </div>
        <DataTable 
            v-if="client.hasOwnProperty('projects')"
            :headers="projectHeaders" 
            :data="client.projects" 
            :total-items="client.projects_count" />
        <div class="flex w-full justify-start my-5">
            <span class="my-auto text-lg font-bold text-custom-blue">Audits</span>
        </div>
        <DataTable 
            v-if="projectAudits"
            :headers="auditHeaders" 
            :data="projectAudits" 
            :total-items="projectAudits.length" />
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import { mapState, mapGetters } from 'vuex'

    export default {
        components: {
            DataTable
        },
        data: () => ({
            projectHeaders: [
                {
                    column: 'Project Name',
                    prop: 'name'
                },
                {
                    column: 'project id',
                    prop: 'project_id'
                },
                {
                    column: 'Year',
                    prop: 'year'
                },
                {
                    column: 'Type',
                    prop: 'type_name'
                }
            ],
            auditHeaders: [
                {
                    column: 'Audit Number',
                    prop: 'audit_id'
                },
                {
                    column: 'Project Name',
                    prop: 'project_name'
                },
                {
                    column: 'Client Name',
                    prop: 'client_name'
                },
                {
                    column: 'Year',
                    prop: 'year'
                },
                {
                    column: 'Status',
                    prop: 'client_status'
                }
            ],
        }),
        computed: {
            ...mapState({
                client: state => state.clientModule.client
            }),
            ...mapGetters('clientModule', ['projectAudits'])
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('clientModule/getClientProfile')
            this.$store.dispatch('setLoading', false)
        }
    }
</script>