<template>
    <div>
        <div class="flex w-full justify-start">
            <span class="my-auto text-lg font-bold text-custom-blue">Audit Procedures</span>
        </div>
        <div class="flex h-full">
            <Stepper />
            <router-view class="flex-grow border ml-5 bg-white rounded overflow-y-scroll max-w-2xl"></router-view>
        </div>
    </div>
</template>
<script>
    import Stepper from './Stepper'
    export default {
        components: {
            Stepper
        },
        data: () => ({
            
        }),
        methods: {
            
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('auditProcedureModule/getProcedures')
            this.$store.dispatch('setLoading', false)
        }
    }
</script>