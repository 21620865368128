import axios from 'axios'
import { at } from 'lodash'

export default {
    namespaced: true,
    state: {
        projectAudit: {
            questionnaire: []
        },
        projectAuditForm: []
    },
    getters: {
        isProjectAuditExternalStatusComplete(state){
            return state.projectAudit.client_status == 'Complete' && state.projectAudit.prior_auditor_status == 'Complete'
        },
        isAdminStatusComplete(state){
            return state.projectAudit.admin_status == 'Complete'
        },
        isPeerReviewerStatusComplete(state){
            return state.projectAudit.peer == 'Complete'
        }
    },
    mutations: {
        MOVE_STEP(state, action){
            let activeStep = state.steps.filter(step => step.status == 'active')
			let enabledSteps = state.steps.filter(step => step.enabled == true)
			for(let i = 0; i < enabledSteps.length; i++){
				enabledSteps[i].step = i+1
			}
			if(action == 'next'){
				enabledSteps[activeStep[0].step-1].status = 'done'
				enabledSteps[activeStep[0].step].status = 'active'
				state.activeStep = enabledSteps[activeStep[0].step].name
			}
			else{
				enabledSteps[activeStep[0].step-2].status = 'active'
				enabledSteps[activeStep[0].step-1].status = 'pending'
				state.activeStep = enabledSteps[activeStep[0].step-2].name
			}
        },
        SET_PROJECT_AUDIT(state, projectAudit){
            state.projectAudit = projectAudit
        }
    },
    actions: {
        moveStep({commit}, action){
            commit('MOVE_STEP', action)
        },
        async getProjectAudit({commit}, projectAuditId){
            await axios.get(`${this.state.endpoint}/project/audit/${projectAuditId}`)
            .then(response => {
                commit('SET_PROJECT_AUDIT', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async saveQuestionnaire({}, payload){
            if(payload.hasOwnProperty('attachments')){
                let apiPayload = new FormData()

                apiPayload.append('questionnaire', JSON.stringify(payload.questionnaire))
                payload.activeForms.forEach(id => apiPayload.append('active_procedure_ids[]', id))

                if(payload.hasOwnProperty('isComplete')){
                    // 5 = complete | 4 = ongoing
                    if(this.getters.isPriorAuditor){
                        apiPayload.append('prior_auditor_status', payload.isComplete ? 5 : 4)
                    }
                    else if(this.getters.isPeerReviewer){
                        apiPayload.append('peer_reviewer_status', payload.isComplete ? 5 : 4)
                    }
                    else if(this.getters.isTeam){
                        apiPayload.append('admin_status', payload.isComplete ? 5 : 4)
                    }
                    else{
                        apiPayload.append('client_status', payload.isComplete ? 5 : 4)
                    }
                }

                //can't append a object with files so added a map to organize files by folder when stored to dropbox
                let attachmentMap = {}
                let index = 0
                for(const [key, value] of Object.entries(payload.attachments)){
                    attachmentMap[key] = {}
                    value.forEach(val => {
                        attachmentMap[key][val.folder] = []
                        if(val.hasOwnProperty('files')){
                            Array.from(val.files).forEach(attachment => {
                                attachmentMap[key][val.folder].push(index)
                                index++
                                apiPayload.append('attachments[]', attachment, attachment.name)
                            })
                        }
                    })
                }

                apiPayload.append('attachment_map', JSON.stringify(attachmentMap))

                apiPayload.append('_method', 'PATCH')
                await axios.post(`${this.state.endpoint}/project/audit/${payload.projectAuditId}`, apiPayload,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${apiPayload._boundary}`
                    }
                })
                .then(response => {
                    this.commit('SET_SNACKBAR', {
                        message: response.data.message,
                        color: 'green'
                    })
                    return true
                })
                .catch(err => {
                    if(err.response.status == 401){
                        this.dispatch('redirectToLogin')
                    }
                })
            }
            else{
                let apiPayload = {
                    questionnaire: JSON.stringify(payload.questionnaire)
                }
                if(payload.hasOwnProperty('isComplete')){
                    if(this.getters.isPriorAuditor){
                        apiPayload.prior_auditor_status = payload.isComplete ? 5 : 4 // 5 = complete | 4 = ongoing
                    }
                    else if(this.getters.isPeerReviewer){
                        apiPayload.peer_reviewer_status = payload.isComplete ? 5 : 4 // 5 = complete | 4 = ongoing
                    }
                    else if(this.getters.isTeam){
                        apiPayload.admin_status = payload.isComplete ? 5 : 4
                    }
                    else{
                        apiPayload.client_status = payload.isComplete ? 5 : 4 // 5 = complete | 4 = ongoing
                    }
                }

                if(payload.hasOwnProperty('activeForms')){
                    apiPayload.active_procedure_ids = payload.activeForms
                }

                if(payload.hasOwnProperty('hasChanges')){
                    apiPayload.questionnaire_changelog = payload.questionnaireChangeLog
                    apiPayload.has_changes = payload.hasChanges
                    if(payload.questionnaireChangeLog.hasOwnProperty('Prior Auditor Response')){
                        apiPayload.prior_auditor_status = 4
                    }

                    for(const [key, value] of Object.entries(payload.questionnaireChangeLog)){
                        if(['Fraud Risk', 'Internal Control', 'Document Request'].includes(key)){
                            apiPayload.client_status = 4
                            break
                        }
                    }
                }

                await axios.patch(`${this.state.endpoint}/project/audit/${payload.projectAuditId}`, apiPayload)
                .then(response => {
                    this.commit('SET_SNACKBAR', {
                        message: response.data.message,
                        color: 'green'
                    })
                    return true
                })
                .catch(err => {
                    if(err.response.status == 401){
                        this.dispatch('redirectToLogin')
                    }
                })
            }
        },
        async savePeerReview({}, payload){
            await axios.patch(`${this.state.endpoint}/project/audit/peer-review/${payload.projectAuditId}`, {
                peer_reviewer_status: payload.isComplete ? 5 : 4,
                peer_review: payload.peerReview
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async saveAdminReview({}, payload){
            await axios.patch(`${this.state.endpoint}/project/audit/admin-review/${payload.projectAuditId}`, {
                admin_status: payload.isComplete ? 5 : 4,
                questionnaire: JSON.stringify(payload.questionnaire)
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        }
    }
}