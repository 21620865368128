import axios from 'axios'

export default {
    namespaced: true,
    state: {
        result: [],
        total: [],
        queryParams: ''
    },
    getters: {
        
    },
    mutations: {
        SET_RESULT(state, result){
            state.result = result.data
            state.total = result.total
        },
        SET_QUERY_PARAMS(state, queryParams){
            state.queryParams = queryParams
        }
    },
    actions: {
        async generateReport({state, commit}, payload){
            await this.dispatch('reportsModule/generateQueryParams', payload).then(response => {
                commit('SET_QUERY_PARAMS', response)
            })
            await axios.get(`${this.state.endpoint}/reports?${state.queryParams}`)
            .then(response => {
                commit('SET_RESULT', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async exportReport({state, commit}, payload){
            await this.dispatch('reportsModule/generateQueryParams', payload).then(response => {
                commit('SET_QUERY_PARAMS', response)
            })
            let type = payload.fileType == 'csv' ? 'csv' : 'pdf'
            await axios.get(`${this.state.endpoint}/reports/export-to-${type}?${state.queryParams}`, {
                responseType: 'blob'
            })
            .then(response => {
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')

                link.href = url
                link.setAttribute('download', `${payload.filename}.${type}`)
                document.body.appendChild(link)

                link.click()
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async generateQueryParams({}, payload){
            let queryParams = `filter=${payload.filter.toLowerCase()}&sort=${payload.sort.toLowerCase()}&order=${payload.order.toLowerCase()}`
            if(payload.costPer == 'Client'){
                queryParams += `&client_id=${payload.clientId}`
            }
            else if(payload.costPer == 'Project'){
                queryParams += `&client_id=${payload.clientId}&project_id=${payload.projectId}`
            }
            else if(payload.costPer == 'Staff'){
                queryParams += `&user_id=${payload.userId}`
            }

            if(payload.filter == 'Month'){
                queryParams += `&month=${payload.month}&year=${payload.year}`
            }
            else if(payload.filter == 'Quarter'){
                queryParams += `&quarter=${payload.quarter}&year=${payload.year}`
            }
            else if(payload.filter == 'Year'){
                queryParams += `&year=${payload.year}`
            }

            return queryParams
        }
    }
}