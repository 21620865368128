<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Users</span>
            <div class="flex space-x-5">
                <Search @search="searchUsers" @clear="searchUsers" />
                <button class="btn btn-primary" @click="showCreateDialog">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    <span>Create</span>
                </button>
            </div>
        </div>
        <DataTable 
            :headers="userHeaders" 
            :data="users" 
            :total-items="totalUsers" 
            :actions="['edit', 'delete']" 
            :item="{id: 'user_id', name: 'full_name'}"
            @editItem="showEditDialog" 
            @deleteItem="showDeleteConfirmation" />
        <Dialog v-if="showUserDialog" @closeDialog="showUserDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue">Create User</span>
            </template>
            <template v-slot:body>
                <div class="border rounded my-2 mx-5 px-5 py-2 border-red-500 bg-red-50 text-red-500 text-sm" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg[0] }}</span>
                </div>
                <div class="flex flex-col m-5 text-custom-blue space-y-1">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">First Name</span>
                            <span v-if="hasError('first_name')" class="text-xs text-red-500">{{ errors.first_name }}</span>
                        </div>
                        <input v-model="userForm.first_name" type="text" class="input-text" :class="{'border-red-500': hasError('first_name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Last Name</span>
                            <span v-if="hasError('last_name')" class="text-xs text-red-500">{{ errors.last_name }}</span>
                        </div>
                        <input v-model="userForm.last_name" type="text" class="input-text" :class="{'border-red-500': hasError('last_name') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Email</span>
                            <span v-if="hasError('email')" class="text-xs text-red-500">{{ errors.email }}</span>
                        </div>
                        <input v-model="userForm.email" type="text" class="input-text" :class="{'border-red-500': hasError('email') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Role</span>
                            <span v-if="hasError('role_id')" class="text-xs text-red-500">{{ errors.role_id }}</span>
                        </div>
                        <select v-model="userForm.role_id" :class="{'border-red-500': hasError('role_id') }">
                            <option value="">Choose a role</option>
                            <option :value="role.role_id" v-for="(role, index) in clientRoles" :key="index">{{ role.name }}</option>
                        </select>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="createUser" v-if="!editMode">Add</button>
                <button class="btn-small btn-secondary" @click="updateUser" v-else>Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showConfirmation" @cancel="showConfirmation = false" @deleteItem="deleteUser" :is-strict="true">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete <span class="text-blue-600">[{{ itemToBeDeleted.name }}]</span>?
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import Dialog from '../../../reusables/Dialog'
    import Search from '../../../reusables/Search'
    import Confirmation from '../../../reusables/Confirmation'
    import { mapGetters, mapState } from 'vuex'

    export default {
        components: {
            DataTable,
            Search,
            Dialog,
            Confirmation
        },
        data: () => ({
            userHeaders: [
                {
                    column: 'Full Name',
                    prop: 'full_name'
                },
                {
                    column: 'Email',
                    prop: 'email'
                },
                {
                    column: 'Type',
                    prop: 'role'
                }
            ],
            userForm: {
                first_name: '',
                last_name: '',
                email: '',
                role_id: '',
                client_id: []
            },
            itemToBeDeleted: [],
            editMode: false,
            errors: {},
            validated: false,
            errorMessage: '',
            showUserDialog: false,
            showConfirmation: false,
            hasUserFormChanges: false,
            userFormCopy: {},
        }),
        computed: {
            ...mapState({
                users: state => state.userModule.users,
                totalUsers: state => state.userModule.totalUsers,
                clientId: state => state.userModule.clientId,
            }),
            ...mapGetters('roleModule', ['clientRoles'])
        },
        watch: {
            userForm: {
                handler(){
                    this.hasUserFormChanges = (this.userForm.first_name != this.userFormCopy.first_name || this.userForm.last_name != this.userFormCopy.last_name || this.userForm.email != this.userFormCopy.email || this.userForm.role_id != this.userFormCopy.role_id) ? true : false
                    this.hasClientChanges = (this.userForm.client_id[0] != this.clientId[0]) ? true : false

                    if(this.validated){
                        this.validate()
                    }
                },
                deep: true
            },
            'userForm.role_id'(val){
                if([1,2,3,7].includes(val)){
                    this.userForm.client_id = []
                }
            }
        },
        methods: {
            async searchUsers(keyword){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('userModule/getAuthenticatedClientUsers', keyword)
                this.$store.dispatch('setLoading', false)
            },
            showCreateDialog(){
                this.showUserDialog = true
                this.editMode = false
                this.errorMessage = ''
                this.resetForm()
            },
            async createUser(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    await this.$store.dispatch('userModule/createUser', {...this.userForm, client_id: [this.clientId]})
                    .then(response => {
                        if(response.length){
                            this.errorMessage = response
                        }
                        else{
                            this.showUserDialog = false
                            this.validated = false
                        }
                    })
                    await this.$store.dispatch('userModule/getAuthenticatedClientUsers')
                    this.$store.dispatch('setLoading', false)
                }
            },
            showEditDialog(user){
                this.editMode = true
                Object.assign(this.userForm, user) // assign object to remove reactive state
                Object.assign(this.userFormCopy, user) //make a copy for changes checking due to api having separate endpoint for changing users' clients
                this.$delete(this.userFormCopy, 'client_id')
                Object.assign(this.clientId, user.client_id) //separated copy of client due to array inside object still being reactive
                this.showUserDialog = true
            },
            async updateUser(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    this.showUserDialog = false
                    await this.$store.dispatch('userModule/updateUser', {
                        user: this.userForm,
                        hasUserFormChanges: this.hasUserFormChanges,
                        hasClientChanges: this.hasClientChanges
                    })
                    await this.$store.dispatch('userModule/getAuthenticatedClientUsers')
                    this.resetForm()
                    this.$store.dispatch('setLoading', false)
                    this.validated = false
                    this.hasUserFormChanges = false
                }
            },
            showDeleteConfirmation(user){
                this.itemToBeDeleted = user
                this.showConfirmation = true
            },
            async deleteUser(){
                this.$store.dispatch('setLoading', true)
                this.showConfirmation = false
                await this.$store.dispatch('userModule/deleteUser', this.itemToBeDeleted.id)
                await this.$store.dispatch('userModule/getAuthenticatedClientUsers')
                this.itemToBeDeleted = []
                this.$store.dispatch('setLoading', false)
            },
            resetForm(){
                this.userForm = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    role_id: '',
                    client_id: []
                }
            },
            validate(){
                this.errors = {}
                if(!this.userForm.first_name){
                    this.errors.first_name = 'Required'
                }
                if(!this.userForm.last_name){
                    this.errors.last_name = 'Required'
                }
                if(!this.userForm.email){
                    this.errors.email = 'Required'
                }
                else{
                    if(!/.+@.+\..+/.test(this.userForm.email)){
                        this.errors.email = 'Invalid email format'
                    }
                }
                if(!this.userForm.role_id){
                    this.errors.role_id = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            }
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('userModule/getAuthenticatedClientUsers')
            await this.$store.dispatch('roleModule/getRoles')
            this.$store.dispatch('setLoading', false)
        }
    }
</script>