<template>
    <div class="fixed flex top-0 left-0 w-full h-screen bg-gray-500 bg-opacity-30 z-20">
        <div class="relative flex flex-col border-2 bg-white m-auto w-auto rounded p-5 max-w-md">
            <div class="mb-5">
                <slot name="message" />
            </div>
            <div class="inline-flex w-full space-x-2" :class="{'justify-end': !isStrict}">
                <input v-model="confirmation" type="text" placeholder="Type 'DELETE' here" class="input-text" v-if="isStrict">
                <button class="btn" :class="isConfirmed ? 'btn-secondary' : 'btn-disabled'" :disabled="!isConfirmed" @click="$emit('deleteItem')" v-if="isStrict">Delete</button>
                <button class="btn btn-secondary" @click="$emit('deleteItem')" v-else>Delete</button>
                <button class="btn btn-tertiary" @click="$emit('cancel')">Cancel</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            isStrict: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            confirmation: '',
            isConfirmed: false
        }),
        watch: {
            confirmation(val){
                this.isConfirmed = val === 'DELETE'
            }
        }
    }
</script>