<template>
    <div class="fixed flex top-0 left-0 w-full h-screen bg-gray-500 bg-opacity-30 z-20">
        <div class="relative flex flex-col border-2 bg-white m-auto w-auto rounded p-5 max-w-md">
            <div class="mb-5">
                <slot name="message" />
            </div>
            <div class="inline-flex w-full space-x-2 justify-end">
                <button class="btn btn-secondary" @click="$emit('success')">Okay</button>
                <button class="btn btn-tertiary" @click="$emit('cancel')">Cancel</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: () => ({

        }),
    }
</script>