import axios from 'axios'

export default {
    namespaced: true,
    state: {
        clients: [],
        client: [],
        totalClients: 0,
        clientList: [] //for options that need complete list of clients
    },
    getters: {
        clientList(state){
            return state.clientList.map(client => ({
                value: client.client_id,
                text: client.name
            }))
        },
        clientListWithAll(state){
            let clients = state.clientList.map(client => ({
                value: client.client_id,
                text: client.name
            }))

            clients.unshift({
                value: 'all',
                text: 'All'
            })

            return clients
        },
        projectAudits(state){
            let audits = []
            if(state.client.hasOwnProperty('projects')){
                state.client.projects.forEach(project => {
                    project.project_audits.forEach(audit => {
                        audits.push(audit)
                    })
                }) 
            }
            return audits
        }
    },
    mutations: {
        SET_CLIENTS(state, clients){
            state.clients = clients.data
            state.totalClients = clients.meta.total
        },
        SET_CLIENT_LIST(state, clients){
            state.clientList = clients
        },
        SET_CLIENT(state, client){
            state.client = client
        }
    },
    actions: {
        async getClients({commit}, payload){
            let clientEndpoint = this.getters.isSuperAdminOrAdmin ? 'clients' : 'assigned-clients'
            let url = payload.hasOwnProperty('keyword') && payload.keyword != null ? `${this.state.endpoint}/${clientEndpoint}?page=${payload.page}&q=${payload.keyword}` : `${this.state.endpoint}/${clientEndpoint}?page=${payload.page}`
            await axios.get(url)
            .then(response => {
                commit('SET_CLIENTS', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getClientList({commit}){
            await axios.get(`${this.state.endpoint}/clients/list`)
            .then(response => {
                commit('SET_CLIENT_LIST', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getClient({commit}, clientId){
            await axios.get(`${this.state.endpoint}/client/${clientId}`)
            .then(response => {
                commit('SET_CLIENT', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getClientProfile({commit}){
            await axios.get(`${this.state.endpoint}/client/profile`)
            .then(response => {
                commit('SET_CLIENT', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async createClient({}, client){
            return await axios.post(`${this.state.endpoint}/client`, client)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async updateClient({}, client){
            return await axios.patch(`${this.state.endpoint}/client/${client.client_id}`, client)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async deleteClient({}, client){
            await axios.delete(`${this.state.endpoint}/client/${client}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        }
    }
}