<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Reports</span>
            <div class="flex space-x-5">
                <button class="btn btn-tertiary" v-if="result.length" @click="exportReport('csv')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span>CSV</span>
                </button>
                <button class="btn btn-tertiary" v-if="result.length" @click="exportReport('pdf')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span>PDF</span>
                </button>
                <button class="btn btn-primary" @click="showOptionsDialog = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span>Generate</span>
                </button>
            </div>
        </div>
        <div class="mt-2 overflow-x-auto w-full" v-if="!showPlaceholder">
            <div class="text-lg py-3 font-bold text-custom-blue">
                {{ reportTitle }}
            </div>
            <table class="table-fixed border w-full">
                <thead class="bg-custom-blue">
                    <tr class="text-sm text-white">
                        <th class="p-5 text-center w-56">Client</th>
                        <th class="p-5 text-center w-40">Project</th>
                        <th class="p-5 text-center w-40">Project Manager</th>
                        <th class="p-5 text-center w-28">Date</th>
                        <th class="p-5 text-center w-28">Hourly Rate</th>
                        <th class="p-5 text-center w-28">Hours</th>
                        <th class="p-5 text-center w-28">Bill</th>
                    </tr>
                </thead>
                <tbody v-if="result.length">
                    <tr v-for="(row, index) in result" :key="`ms-${index}`" class="text-xs hover:bg-gray-300 border-b-2">
                        <td class="p-2 text-left">{{ row.client_name }}</td>
                        <td class="p-2 text-left">{{ row.project_name }}</td>
                        <td class="p-2 text-left">{{ row.first_name }} {{ row.last_name }}</td>
                        <td class="p-2 text-left">{{ row.date }}</td>
                        <td class="p-2 text-left">$ {{ row.hourly_rate }}</td>
                        <td class="p-2 text-left">{{ row.hours }}</td>
                        <td class="p-2 text-left">$ {{ row.bill }}</td>
                    </tr>
                    <tr class="bg-gray-200">
                        <td colspan="5"></td>
                        <td class="text-md font-bold p-3 text-center">{{ total.hours }}</td>
                        <td class="text-md font-bold p-3 text-center">$ {{ total.bill }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-center text-gray-400 p-3">
                            No Data Available
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex flex-col w-full items-center justify-center h-5/6 bg-white border mt-2 rounded" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-40 w-40 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
        </div>
        <Dialog v-show="showOptionsDialog" @closeDialog="showOptionsDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue">Options</span>
            </template>
            <template v-slot:body>
                <div class="flex flex-col m-5 text-custom-blue space-y-1">
                    <div class="flex flex-row justify-between">
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Cost per</span>
                            </div>
                            <Dropdown v-model="form.costPer" :options="['Client', 'Project', 'Staff']" :default="'Client'" />
                        </div>
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Filter</span>
                            </div>
                            <Dropdown v-model="form.filter" :options="['Month', 'Quarter', 'Year']" :default="'Month'" />
                        </div>
                    </div>
                    <div class="flex flex-col" v-if="['Client', 'Project'].includes(form.costPer)">
                        <span class="font-semibold">Client</span>
                        <model-select
                            :options="clientList"
                            v-model="form.clientId"
                        ></model-select>
                    </div>
                    <div class="flex flex-col" v-if="form.costPer == 'Project'">
                        <span class="font-semibold">Project</span>
                        <model-select
                            :options="projects"
                            v-model="form.projectId"
                            placeholder="Select a client first"
                        ></model-select>
                    </div>
                    <div class="flex flex-col" v-if="form.costPer == 'Staff'">
                        <span class="font-semibold">Staff</span>
                        <model-select
                            :options="internalUsersList"
                            v-model="form.userId"
                        ></model-select>
                    </div>
                    <div class="flex flex-row justify-between">
                        <div class="flex flex-col" v-show="form.filter == 'Month'">
                            <span class="font-semibold">Month</span>
                            <Dropdown v-model="form.month" :options="months" :default="'January'" />
                        </div>
                        <div class="flex flex-col" v-show="form.filter == 'Quarter'">
                            <span class="font-semibold">Quarter</span>
                            <Dropdown v-model="form.quarter" :options="quarters" :default="'First'" />
                        </div>
                        <div class="flex flex-col">
                            <span class="font-semibold">Year</span>
                            <Dropdown v-model="form.year" :options="years" :default="defaultYear" />
                        </div>
                    </div>
                    <div class="flex flex-row justify-between">
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Sort by</span>
                            </div>
                            <Dropdown v-model="form.sort" :options="['Client', 'Project', 'Staff']" :default="'Client'" />
                        </div>
                        <div class="flex flex-col">
                            <div class="inline-flex justify-between mb-1">
                                <span class="font-semibold">Order</span>
                            </div>
                            <Dropdown v-model="form.order" :options="['Asc', 'Desc']" :default="'Asc'" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="generateReport">Generate</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import { mapState, mapGetters }  from 'vuex'
    import Dropdown from '../../../reusables/Dropdown'
    import Dialog from '../../../reusables/Dialog'
    import { ModelSelect } from 'vue-search-select'

    export default {
        components: {
            Dropdown,
            Dialog,
            ModelSelect
        },
        data: () => ({
            defaultYear: '',
            showOptionsDialog: false,
            form: {
                month: 1,
                year: 0,
                clientId: 0,
                costPer: 'Client',
                filter: 'Month',
                projectId: 0,
                userId: 0,
                sort: 'Client',
                order: 'Asc',
                quarter: 1,
                reportTitle: ''
            },
            quarters: [
                {
                    text: 'First',
                    value: '1'
                },
                {
                    text: 'Second',
                    value: '2'
                },
                {
                    text: 'Third',
                    value: '3'
                },
                {
                    text: 'Fourth',
                    value: '4'
                }
            ],
            showPlaceholder: true
        }),
        computed: {
            ...mapState({
                months: state => state.months,
                result: state => state.reportsModule.result,
                total: state => state.reportsModule.total
            }),
            ...mapGetters('clientModule', ['clientList']),
            ...mapGetters('projectModule', ['projects']),
            ...mapGetters('userModule', ['internalUsersList']),
            years(){
                const date = new Date()
                let years = []
                let year = date.getFullYear()
                while(year >= 2017){
                    years.push(year.toString())
                    year--
                }
                return years
            }
        },
        watch: {
            'form.clientId'(val){
                this.$store.dispatch('setLoading', true)
                this.$store.dispatch('projectModule/getProjectsByClient', val)
                this.$store.dispatch('setLoading', false)
            }
        },
        methods: {
            async generateReport(){
                this.$store.dispatch('setLoading', true)
                this.showPlaceholder = false
                this.showOptionsDialog = false
                this.generateTitle()
                await this.$store.dispatch('reportsModule/generateReport', this.form)
                this.$store.dispatch('setLoading', false)
            },
            async exportReport(fileType){
                this.$store.dispatch('setLoading', true)
                this.showPlaceholder = false
                this.showOptionsDialog = false
                this.generateTitle()
                await this.$store.dispatch('reportsModule/exportReport', {
                    ...this.form,
                    filename: this.reportTitle,
                    fileType: fileType
                })
                this.$store.dispatch('setLoading', false)
            },
            generateTitle(){
                let costPer = ''
                let filter = ''

                if(this.form.costPer == 'Client'){
                    costPer = this.clientList.filter(client => client.value == this.form.clientId)[0].text
                }
                else if(this.form.costPer == 'Project'){
                    let project = this.projects.filter(project => project.value == this.form.projectId)[0].text
                    let client = this.clientList.filter(client => client.value == this.form.clientId)[0].text
                    costPer = `${client} - ${project}`
                }
                else if(this.form.costPer == 'Staff'){
                    costPer = this.internalUsersList.filter(admin => admin.value == this.form.userId)[0].text
                }

                if(this.form.filter == 'Month'){
                    let month = this.months.filter(month => month.value == this.form.month)[0].text
                    filter = `${month} ${this.form.year}`
                }
                else if(this.form.filter == 'Quarter'){
                    let quarter = this.quarters.filter(quarter => quarter.value == this.form.quarter)[0].text
                    filter = `${quarter} Quarter ${this.form.year}`
                }
                else if(this.form.filter == 'Year'){
                    filter = this.form.year
                }

                this.reportTitle = `${costPer} - ${filter}`
            }
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('clientModule/getClientList')
            await this.$store.dispatch('userModule/getInternalUsers')
            this.$store.dispatch('setLoading', false)
        },
        created(){
            let date = new Date()
            this.defaultYear = this.form.year = date.getFullYear().toString()
        }
    }
</script>