<template>
    <div>
        <div class="flex w-full justify-between mb-5">
            <button class="inline-flex items-center text-custom-blue" @click="$router.go(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <span class="ml-2">Back</span>
            </button>
            <div class="flex flex-row space-x-2">
                <Dropdown v-model="month" :options="months" :default="defaultMonth" />
                <Dropdown v-model="year" :options="years" :default="defaultYear" />
                <button class="btn btn-primary" @click="filter">Filter</button>
            </div>
        </div>
        <div class="flex flex-col border bg-white rounded">
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">From</span>
                <span class="ml-5 col-span-3">{{ user.full_name }}</span>
            </div>
            <template v-if="user.rate_history.length">
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Date</span>
                    <span class="ml-5 col-span-3">{{ user.rate_history[0].date }}</span>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Total Hours</span>
                    <span class="ml-5 col-span-3">{{ user.rate_history[0].total_hours }}</span>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Hourly Rate</span>
                    <div class="inline-flex space-x-5">
                        <span class="ml-5 col-span-3">$ {{ user.rate_history[0].hourly_rate }}</span>
                        <button class="has-tooltip" @click="showDialog(user.rate_history[0])">
                            <span class="tooltip">Edit</span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Total Billed</span>
                    <span class="ml-5 col-span-3">$ {{ user.rate_history[0].total_billed }}</span>
                </div>
            </template>
        </div>
        <DataTable
            :headers="headers"
            :data="user.rate_history.length ? user.rate_history[0].timesheet : []"
            :has-pagination="false"/>
        <Dialog v-if="showEditDialog" @closeDialog="showEditDialog = false">
            <template v-slot:title>
                <span class="font-semibold text-custom-blue">Update Hourly Rate</span>
            </template>
            <template v-slot:body>
                <div class="flex flex-col mx-5 text-custom-blue space-y-1 mt-2">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Hourly Rate <span class="text-red-500 text-sm">*</span></span>
                            <span v-if="hasError('hourly_rate')" class="text-xs text-red-500">{{ errors.hourly_rate }}</span>
                        </div>
                        <input v-model="hourlyRateForm.hourly_rate" type="text" class="input-text" :class="{'border-red-500': hasError('hourly_rate') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex items-center space-x-2">
                            <input type="checkbox" v-model="hourlyRateForm.update_current_rate" id="update_current_rate">
                            <label for="update_current_rate" class="font-semibold">Update user's default hourly rate?</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="update">Update</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import DataTable from '../../../reusables/DataTable'
    import Dropdown from '../../../reusables/Dropdown'
    import Dialog from '../../../reusables/Dialog'

    export default {
        components: {
            DataTable,
            Dropdown,
            Dialog
        },
        data: () => ({
            headers: [
                {
                    column: 'Date',
                    prop: 'date'
                },
                {
                    column: 'Start Time',
                    prop: 'start_time'
                },
                {
                    column: 'End Time',
                    prop: 'end_time'
                },
                {
                    column: 'Duration',
                    prop: 'duration'
                },
                {
                    column: 'Hours',
                    prop: 'hours'
                },
                {
                    column: 'Project',
                    prop: 'project_name'
                },
                {
                    column: 'Note',
                    prop: 'note'
                },
            ],
            month: 1,
            year: 0,
            defaultMonth: '',
            defaultYear: '',
            showEditDialog: false,
            errors: {},
            hourlyRateForm: {
                id: 0,
                hourly_rate: 0,
                update_current_rate: false
            }
        }),
        computed: {
            ...mapState({
                user: state => state.projectTimeSheetModule.user,
                months: state => state.months
            }),
            years(){
                const date = new Date()
                let years = []
                let year = date.getFullYear()
                while(year >= 2017){
                    years.push(year.toString())
                    year--
                }
                return years
            }
        },
        methods: {
            async getTimeSheet(month, year){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectTimeSheetModule/getUserTimeSheet', {
                    month,
                    year,
                    userId: this.$route.params.id
                })
                this.$store.dispatch('setLoading', false)
            },
            async filter(){
                await this.getTimeSheet(this.month, this.year)
            },
            showDialog(rate){
                this.hourlyRateForm.id = rate.id
                this.hourlyRateForm.hourly_rate = rate.hourly_rate
                this.showEditDialog = true
            },
            validate(){
                this.errors = {}
                if(!this.hourlyRateForm.hourly_rate){
                    this.errors.hourly_rate = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
            async update(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectTimeSheetModule/updateHourlyRate', this.hourlyRateForm)
                await this.filter()
                this.showEditDialog = false
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            let month = this.months.filter(month => month.text == this.defaultMonth)[0].value
            await this.getTimeSheet(month, this.defaultYear)
        },
        created(){
            let date = new Date()
            this.defaultMonth = this.months.filter(month => month.value == date.getMonth()+1)[0].text
            this.defaultYear = this.year = date.getFullYear().toString()
        }
    }
</script>