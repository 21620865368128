
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Login from '../components/Login'
import ForgotPassword from '../components/ForgotPassword'
import ResetPassword from '../components/ResetPassword'
import Client from '../components/views/Admin/Client/Index'
import ClientDetails from '../components/views/Admin/Client/Details'
import Users from '../components/views/Admin/User/Index'
import User from '../components/views/Admin/User/Details'
import ClientUsers from '../components/views/Admin/User/ClientUsers'
import Audit from '../components/views/Admin/Project/Audit/Index'
import Projects from '../components/views/Admin/Project/Index'
import Project from '../components/views/Admin/Project/Details'
import AuditProcedures from '../components/views/Admin/AuditProcedures/Index'
import ClientInfo from '../components/views/Admin/AuditProcedures/ClientInfo'
import PriorAuditorResponse from '../components/views/Admin/AuditProcedures/PriorAuditorResponse'
import FraudRisk from '../components/views/Admin/AuditProcedures/FraudRisk'
import InternalControl from '../components/views/Admin/AuditProcedures/InternalControl'
import DocumentRequest from '../components/views/Admin/AuditProcedures/DocumentRequest'
import ThreeMonthReview from '../components/views/Admin/AuditProcedures/ThreeMonthReview'
import FourthToTwelfthMonthReview from '../components/views/Admin/AuditProcedures/FourthToTwelfthMonthReview'
import YearEndReview from '../components/views/Admin/AuditProcedures/YearEndReview'
import PeerReview from '../components/views/Admin/AuditProcedures/PeerReview'
import Milestone from '../components/views/Admin/Milestone/Index'

import Questionnaire from '../components/views/Client/Audit/Index'
import QuestionnaireList from '../components/views/Client/Audit/Questionnaire'
import Profile from '../components/views/Client/Profile/Index'

import Reports from '../components/views/Admin/Report/Index'

import Settings from '../components/views/Settings/Index'


const routes = [
    {
        component: Login,
        name: 'login',
        path: '/'
    },
    {
        component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/clients',
        children: [
            {
                component: Client,
                name: 'clients',
                path: '',
				meta: {
					auth: true
				}
            },
            {
                component: ClientDetails,
                name: 'clients.details',
                path: ':id',
				meta: {
					auth: true
				}
            }
        ]
    },
    {
        component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/users',
        children: [
            {
                component: Users,
                name: 'users',
                path: '',
				meta: {
					auth: true
				}
            },
            {
                component: User,
                name: 'users.details',
                path: ':id',
				meta: {
					auth: true
				}
            }
        ]
    },
    {
        component: ClientUsers,
        name: 'clientusers',
        path: '/client-users',
        meta: {
            auth: true
        }
    },
    {
        component: Audit,
        name: 'projects.audits',
        path: '/projects/audits',
        meta: {
            auth: true
        }
    },
    {
        component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/',
        children: [
            {
                component: Projects,
                name: 'projects',
                path: 'projects',
				meta: {
					auth: true
				}
            },
            {
                component: Project,
                name: 'projects.details',
                path: 'projects/:id',
				meta: {
					auth: true
				}
            }
        ]
    },
    {
        component: AuditProcedures,
        name: 'auditprocedures',
        path: '/audit-procedures',
        children: [
            {
                component: ClientInfo,
                name: 'auditprocedures.clientinfo',
                path: 'client-info',
				meta: {
					auth: true
				}
            },
            {
                component: PriorAuditorResponse,
                name: 'auditprocedures.priorauditorresponse',
                path: 'prior-auditor-response',
				meta: {
					auth: true
				}
            },
            {
                component: FraudRisk,
                name: 'auditprocedures.fraudrisk',
                path: 'fraud-risk',
				meta: {
					auth: true
				}
            },
            {
                component: InternalControl,
                name: 'auditprocedures.internalcontrol',
                path: 'internal-control',
				meta: {
					auth: true
				}
            },
            {
                component: DocumentRequest,
                name: 'auditprocedures.documentrequest',
                path: 'document-request',
				meta: {
					auth: true
				}
            },
            {
                component: ThreeMonthReview,
                name: 'auditprocedures.threemonthreview',
                path: 'three-month-review',
				meta: {
					auth: true
				}
            },
            {
                component: FourthToTwelfthMonthReview,
                name: 'auditprocedures.fourthtotwelfthmonthreview',
                path: 'fourth-to-twelfth-month-review',
				meta: {
					auth: true
				}
            },
            {
                component: YearEndReview,
                name: 'auditprocedures.yearendreview',
                path: 'year-end-review',
				meta: {
					auth: true
				}
            },
            {
                component: PeerReview,
                name: 'auditprocedures.peerreview',
                path: 'peer-review',
				meta: {
					auth: true
				}
            }
        ]
    },
    {
        component: QuestionnaireList,
        name: 'questionnairelist',
        path: '/questionnaire',
        meta: {
            auth: true
        }
    },
    {
        component: Questionnaire,
        name: 'questionnaire',
        path: '/questionnaire/:id',
        meta: {
            auth: true
        }
    },
    {
        component: Profile,
        name: 'profile',
        path: '/profile',
        meta: {
            auth: true
        }
    },
    {
        component: Milestone,
        name: 'milestone',
        path: '/milestone',
        meta: {
            auth: true
        }
    },
    {
        component: Reports,
        name: 'reports',
        path: '/reports',
        meta: {
            auth: true
        }
    },
    {
        component: Settings,
        name: 'settings',
        path: '/settings',
        meta: {
            auth: true
        }
    },
    {
		component: ForgotPassword,
		name: 'forgotpassword',
		path: '/forgot-password'
	},
	{
		component: ResetPassword,
		name: 'resetpassword',
		path: '/reset-password/:token'
	},
]

let router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	let hasToken = localStorage.getItem('bearerToken') != null && localStorage.getItem('bearerToken') != 'undefined'
    if(to.fullPath == '/'){
        if(hasToken){
            let role = JSON.parse(localStorage.getItem('vuex')).user.role
            let redirectPath = ''
            if(['Super Administrator', 'Administrator', 'Accountant'].includes(role)){
                redirectPath = 'clients'
            }
            else if(['CEO', 'CFO'].includes(role)){
                redirectPath = 'profile'
            }
            else{
                redirectPath = 'questionnairelist'
            }
            next({
                name: redirectPath
            })
        }
        else{
            next()
        }
    }
    else{
        if(to.matched.some(route => route.meta.auth)){
            if(hasToken){
                next()
            }
            else{
                next({
                    name: 'login'
                })
            }
        }
        else{
            if(to.matched.length == 0){
                next({
                    name: 'login'
                })
            }
            else{
                next()
            }
        }
    }
})

export default router