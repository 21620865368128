<template>
    <div class="fixed flex top-0 left-0 w-full h-screen bg-gray-500 bg-opacity-30 z-20">
        <div class="relative border-2 bg-white m-auto w-auto rounded">
            <div class="absolute top-3 right-3 cursor-pointer" @click="$emit('closeDialog')">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="border-b flex py-3 pl-5 pr-10">
                <slot name="title" />
            </div>
            <div class="w-96 text-sm">
                <slot name="body" />
            </div>
            <div class="max-w-full text-sm">
                <slot name="bodyfull" />
            </div>
            <div class="flex justify-end py-3 pr-5 space-x-3">
                <slot name="action" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        
    }
</script>