import axios from 'axios'

export default {
    namespaced: true,
    state: {
        procedures: []
    },
    getters: {
        priorAuditorResponse(state){
            return state.procedures.filter(procedure => procedure.step == 'Prior Auditor Response')[0]
        },
        fraudRisk(state){
            return state.procedures.filter(procedure => procedure.step == 'Fraud Risk')[0]
        },
        internalControl(state){
            return state.procedures.filter(procedure => procedure.step == 'Internal Control')[0]
        },
        documentRequest(state){
            return state.procedures.filter(procedure => procedure.step == 'Document Request')[0]
        },
        threeMonthReview(state){
            return state.procedures.filter(procedure => procedure.step == 'First Three Month')[0]
        },
        fourthToTwelfthMonthReview(state){
            return state.procedures.filter(procedure => procedure.step == '4th to 12th Month Review')[0]
        },
        yearEndReview(state){
            return state.procedures.filter(procedure => procedure.step == 'Year End Review')[0]
        }
    },
    mutations: {
        SET_PROCEDURES(state, procedures){
            state.procedures = procedures.data
        }
    },
    actions: {
        getProcedures({commit}){
            axios.get(`${this.state.endpoint}/audit-procedures`)
            .then(response => {
                commit('SET_PROCEDURES', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        createQuestion({}, question){
            return axios.post(`${this.state.endpoint}/audit-procedures`, question)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async updateQuestion({}, question){
            return axios.patch(`${this.state.endpoint}/audit-procedures/${question.audit_procedure_id}`, question)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async deleteQuestion({}, auditProcedureId){
            await axios.delete(`${this.state.endpoint}/audit-procedures/${auditProcedureId}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
    }
}