import axios from 'axios'

export default {
    namespaced: true,
    state: {
        roles: []
    },
    getters: {
        clientRoles(state){
            return state.roles.filter(role => ['CEO', 'CFO'].includes(role.name))
        }
    },
    mutations: {
        SET_ROLES(state, roles){
            state.roles = roles
        }
    },
    actions: {
        async getRoles({commit}){
            await axios.get(`${this.state.endpoint}/roles`)
            .then(response => {
                commit('SET_ROLES', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        }
    }
}