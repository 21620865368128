<template>
    <div class="absolute flex z-10  w-full justify-center">
        <div class="mx-auto mt-3 shadow border rounded text-white px-5 py-2 max-w-sm text-sm" :class="snackbar.color == 'green' ? 'bg-green-500' : 'bg-red-500'">
            {{ snackbar.message }}
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        computed: {
            ...mapState({
                snackbar: state => state.snackbar
            })
        }
    }
</script>