<template>
    <div>
        <div class="border rounded bg-white w-72 h-auto">
            <div class="border-b px-3 py-4">
                <span class="text-gray-400 text-sm">AUDIT STEPS</span>
            </div>
            <router-link v-for="(step, index) in steps" class="flex justify-between border-b p-3 items-center" :class="{'active-step': $route.name == step.path}" :key="index" :to="{ name: step.path }">
                <span class="text-sm">{{ index+1 }} - {{ step.name }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        steps: [
            {
                name: 'Client Info',
                path: 'auditprocedures.clientinfo'
            },
            {
                name: 'Prior Auditor Response',
                path: 'auditprocedures.priorauditorresponse'
            },
            {
                name: 'Fraud Risk',
                path: 'auditprocedures.fraudrisk'
            },
            {
                name: 'Internal Control',
                path: 'auditprocedures.internalcontrol'
            },
            {
                name: 'Document Request',
                path: 'auditprocedures.documentrequest'
            },
            {
                name: '3 Month Review',
                path: 'auditprocedures.threemonthreview'
            },
            {
                name: '4-12 Month Review',
                path: 'auditprocedures.fourthtotwelfthmonthreview'
            },
            {
                name: 'Year End Review',
                path: 'auditprocedures.yearendreview'
            },
            {
                name: 'Peer Review',
                path: 'auditprocedures.peerreview'
            }
        ]
    })
}
</script>