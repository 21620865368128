import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        async changePassword({}, payload){
            await axios.patch(`${this.state.endpoint}/user/change-password`, {
                current_password: payload.currentPassword,
                new_password: payload.newPassword,
                new_password_confirmation: payload.newPasswordConfirmation,
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                this.dispatch('redirectToLogin')
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }

                if(err.response.status == 400){
                    this.commit('SET_SNACKBAR', {
                        message: err.response.data.message,
                        color: 'red'
                    })
                }
            })
        },
        async sendResetPassword({}, email){
            await axios.post(`${this.state.endpoint}/send-reset-password-link`, {
                email: email
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }

                if(err.response.status == 404){
                    this.commit('SET_SNACKBAR', {
                        message: err.response.data.message,
                        color: 'red'
                    })
                }
            })
        },
        async resetPassword({}, payload){
            await axios.patch(`${this.state.endpoint}/reset-password/${payload.token}`, {
                email: payload.email,
                new_password: payload.newPassword,
                new_password_confirmation: payload.newPasswordConfirmation
            })
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                router.push('/')
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }

                if(err.response.status == 404){
                    this.commit('SET_SNACKBAR', {
                        message: err.response.data.message,
                        color: 'red'
                    })
                }
            })
        },
        async resendCredentials({}, userId){
            await axios.patch(`${this.state.endpoint}/user/resend-credentials/${userId}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }

                if(err.response.status == 404){
                    this.commit('SET_SNACKBAR', {
                        message: err.response.data.message,
                        color: 'red'
                    })
                }
            })
        }
    }
}