<template>
    <div class="flex flex-grow h-16 header-border px-5 bg-white" :class="['login', 'questionnaire', 'resetpassword', 'forgotpassword'].includes($route.name) ? 'justify-between' : 'justify-end'">
        <div v-if="['login', 'questionnaire', 'resetpassword', 'forgotpassword'].includes($route.name)">
            <img src="/images/logo.png" alt="HZ CPA" class="w-24 h-16 mx-auto">
        </div>
        <div class="flex my-auto" v-if="!['login', 'resetpassword', 'forgotpassword'].includes($route.name)">
            <div class="border-2 rounded-full w-12 h-12 mr-2"></div>
            <div class="flex flex-col relative">
                <div class="inline-flex items-center">
                    <span class="font-semibold text-base mr-2">{{ user.full_name }}</span>
                    <button @click="showMenu = !showMenu">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                </div>
                <span class="font-thin text-sm">{{ user.role }}</span>
                <transition
                enter-active-class="transform transition duration-200 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100">
                    <div class="absolute top-14 right-0 bg-white border w-40 rounded shadow z-50" v-if="showMenu">
                        <ul class="" @click="showMenu = false">
                            <router-link :to="{ name: 'settings' }">
                                <li class="text-sm hover:bg-gray-200 p-2 cursor-pointer">Settings</li>
                            </router-link>
                            <li class="text-sm hover:bg-gray-200 p-2 cursor-pointer" @click="logout">Log out</li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'
    
    export default {
        data: () => ({
            showMenu: false
        }),
        methods: {
            logout(){
                axios.post('/api/v1/logout')
                .then(response => {
                    if(response.status == 200){
                        this.showMenu = false
                        localStorage.removeItem('bearerToken')
                        localStorage.removeItem('vuex')
                        delete axios.defaults.headers.common['Authorization']
                        this.$router.push({ name: 'login' })
                    }
                }).
                catch(err => {
                    if(err.response.status == 401){
                        this.$store.dispatch('redirectToLogin')
                    }
                })
            },
            close(e){
                if (!this.$el.contains(e.target)) {
                    this.showMenu = false
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.user
            })
        },
        mounted(){
            document.addEventListener('click', this.close)
        },
        beforeDestroy(){
            document.removeEventListener('click', this.close)
        }
    }
</script>