<template>
    <div>
        <div class="flex mt-3 mr-5 space-x-8">
            <div class="mx-2">
                <button class="btn-small" :class="isDisabled ? 'bg-gray-500' : 'btn-secondary'" @click="showPreparedDialog = true" v-if="!value.hasOwnProperty('preparer')" :disabled="isDisabled">Prepare</button>
                <button class="btn-small btn-tertiary" v-else>Prepared</button>
                <div class="flex flex-col mt-1" v-if="value.hasOwnProperty('preparer')">
                    <div class="text-gray-500 text-xs inline-flex items-center">{{ value.preparer.name }}
                        <div class="has-tooltip" v-if="value.preparer.comment">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                            <div class="tooltip flex flex-col">
                                <span>
                                    {{ value.preparer.name }}
                                </span>
                                <span>
                                    {{ value.preparer.timestamp | unixToDateTime }}
                                </span>
                                <span class="mt-5">
                                    {{ value.preparer.comment }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <span class="text-gray-500 text-xs">{{ value.preparer.timestamp | unixToDate }}</span>
                </div>
            </div>
            <div class="mx-2">
                <button class="btn-small" :class="isDisabled ? 'bg-gray-500' : 'btn-secondary'" @click="showReviewedDialog = true" v-if="!value.hasOwnProperty('reviewer')" :disabled="isDisabled">Review</button>
                <button class="btn-small btn-tertiary" v-else>Reviewed</button>
                <div class="flex flex-col mt-1" v-if="value.hasOwnProperty('reviewer')">
                    <div class="text-gray-500 text-xs inline-flex items-center">{{ value.reviewer.name }}
                        <div class="has-tooltip" v-if="value.reviewer.comment">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                            <div class="tooltip flex flex-col">
                                <span>
                                    {{ value.reviewer.name }}
                                </span>
                                <span>
                                    {{ value.reviewer.timestamp | unixToDateTime }}
                                </span>
                                <span class="mt-5">
                                    {{ value.reviewer.comment }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <span class="text-gray-500 text-xs">{{ value.reviewer.timestamp | unixToDate }}</span>
                </div>
            </div>
        </div>
        <Dialog v-if="showPreparedDialog" @closeDialog="showPreparedDialog = false">
            <template v-slot:title>
                <span class="font-semibold">Prepare</span>
            </template>
            <template v-slot:body>
                <div class="flex flex-col mt-3 mx-5">
                    <span class="font-semibold mb-2">Comment</span>
                    <textarea v-model="prepareComment" cols="30" rows="5" placeholder="Optional"></textarea>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="prepare">Prepare</button>
            </template>
        </Dialog>
        <Dialog v-if="showReviewedDialog" @closeDialog="showReviewedDialog = false">
            <template v-slot:title>
                <span class="font-semibold">Review</span>
            </template>
            <template v-slot:body>
                <div class="flex flex-col mt-3 mx-5">
                    <span class="font-semibold mb-2">Comment</span>
                    <textarea v-model="reviewComment" cols="30" rows="5" placeholder="Optional"></textarea>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="review">Review</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import Dialog from '../../../reusables/Dialog'
    import dayjs from 'dayjs'

    export default {
        components:{
            Dialog
        },
        props: {
            value: {
                default: () => ({})
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showPreparedDialog: false,
            showReviewedDialog: false,
            prepareComment: '',
            reviewComment: '',
        }),
        computed: {
            ...mapState({
                userFullName: state => state.user.full_name
            })
        },
        filters: {
            unixToDate(unix){
                if(unix){
                    return dayjs(unix).format('MM/DD/YYYY')
                }
            },
            unixToDateTime(unix){
                if(unix){
                    return dayjs(unix).format('MM/DD/YYYY HH:mm:ss')
                }
            }
        },
        methods: {
            prepare(){
                this.showPreparedDialog = false
                this.value.preparer = {
                    name: this.userFullName,
                    timestamp: Date.now(),
                    comment: this.prepareComment
                }
                this.$emit('input', this.value)
                this.$emit('save')
            },
            review(){
                this.showReviewedDialog = false
                this.value.reviewer = {
                    name: this.userFullName,
                    timestamp: Date.now(),
                    comment: this.reviewComment
                }
                this.$emit('input', this.value)
                this.$emit('save')
            }
        }
    }
</script>