/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');

import Vue from 'vue';
import router from './router'
import store from './store'
import 'vue-search-select/dist/VueSearchSelect.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import Mixins from './plugins/mixins'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('app-component', require('./components/App.vue').default)

Vue.filter('ucfirst', function(value){
    if(value){
        let splits = value.split(' ')
        let formattedText = splits.map(text => `${text.charAt(0)}${text.slice(1).toLowerCase()}`)
        return formattedText.join(' ')
    }
})

Vue.use(wysiwyg, {
    hideModules: {
        "image": true,
        "table": true,
        "code": true,
    }
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    store,
    mixins: Mixins
});
