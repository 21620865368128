import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'

import clientModule from './client'
import userModule from './user'
import projectModule from './project'
import auditProcedureModule from './auditprocedure'
import clientAuditProcedureModule from './clientauditprocedure'
import roleModule from './role'
import projectMilestoneModule from './projectmilestone'
import projectTimeSheetModule from './projecttimesheet'
import reportsModule from './reports'
import authModule from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        clientModule,
        userModule,
        projectModule,
        auditProcedureModule,
        clientAuditProcedureModule,
        roleModule,
        projectMilestoneModule,
        projectTimeSheetModule,
        reportsModule,
        authModule
    },
    state: {
        endpoint: '/api/v1',
        isLoading: false,
        user: {
            full_name: '',
            role: '',
            hourly_rate: ''
        },
        menus: [
            {
                name: 'Clients',
                path: 'clients',
                icon: 'client.svg',
                user: ['Super Administrator', 'Administrator', 'Accountant']
            },
            {
                name: 'Profile',
                path: 'profile',
                icon: 'client.svg',
                user: ['CEO', 'CFO']
            },
            {
                name: 'Users',
                path: 'users',
                icon: 'user.svg',
                user: ['Super Administrator', 'Administrator', 'Accountant']
            },
            {
                name: 'Users',
                path: 'clientusers',
                icon: 'user.svg',
                user: ['CEO', 'CFO']
            },
            {
                name: 'Questionnaire',
                path: 'questionnairelist',
                icon: 'procedures.svg',
                user: ['CEO', 'CFO', 'Prior Auditor', 'Peer Reviewer']
            },
            {
                name: 'Projects',
                path: 'projects',
                icon: 'project.svg',
                user: ['Super Administrator', 'Administrator', 'Accountant'],
                submenu: [
                    {
                        name: 'Audits',
                        path: 'projects.audits',
                        icon: 'audit.svg'
                    },
                    {
                        name: 'Tax',
                        path: '',
                        icon: 'audit.svg'
                    },
                    {
                        name: 'Other Projects',
                        path: '',
                        icon: 'audit.svg'
                    },
                ]
            },
            {
                name: 'Audit Procedures',
                path: 'auditprocedures.clientinfo',
                icon: 'procedures.svg',
                user: ['Super Administrator', 'Administrator', 'Accountant']
            },
            {
                name: 'Milestone',
                path: 'milestone',
                icon: 'project.svg',
                user: ['Super Administrator', 'Administrator', 'Accountant']
            },
            {
                name: 'Reports',
                path: 'reports',
                icon: 'project.svg',
                user: ['Super Administrator', 'Administrator']
            },
        ],
        snackbar: {
            message: '',
            count: 0, //used to check if changes on object since message can be repetitive and won't be considered as change
            color: 'green'
        },
        months: [
            {
                text: 'January',
                value: 1
            },
            {
                text: 'February',
                value: 2
            },
            {
                text: 'March',
                value: 3
            },
            {
                text: 'April',
                value: 4
            },
            {
                text: 'May',
                value: 5
            },
            {
                text: 'June',
                value: 6
            },
            {
                text: 'July',
                value: 7
            },
            {
                text: 'August',
                value: 8
            },
            {
                text: 'September',
                value: 9
            },
            {
                text: 'October',
                value: 10
            },
            {
                text: 'November',
                value: 11
            },
            {
                text: 'December',
                value: 12
            },
        ],
    },
    getters: {
        isSuperAdmin(state){
            return state.user.role == 'Super Administrator'
        },
        isAdmin(state){
            return state.user.role == 'Administrator'
        },
        isSuperAdminOrAdmin(state){
            return ['Super Administrator', 'Administrator'].includes(state.user.role)
        },
        isTeam(state){
            return ['Super Administrator', 'Administrator', 'Accountant'].includes(state.user.role)
        },
        isClientUser(state){
            return ['CEO', 'CFO', 'Accounting Personnel'].includes(state.user.role)
        },
        isPriorAuditor(state){
            return ['Prior Auditor'].includes(state.user.role)
        },
        isPeerReviewer(state){
            return ['Peer Reviewer'].includes(state.user.role)
        },
        menus(state){
            return state.menus.filter(menu => menu.user.includes(state.user.role))
        }
    },
    plugins: [createPersistedState({
        paths: ['user']
    })],
    mutations: {
        SET_LOADING(state, isLoading){
            state.isLoading = isLoading
        },
        SET_USER(state, user){
            state.user = user
        },
        SET_SNACKBAR(state, payload){
            state.snackbar.message = payload.message
            state.snackbar.color = payload.color
            state.snackbar.count++
        }
    },
    actions: {
        redirectToLogin(){
			localStorage.removeItem('bearerToken')
			localStorage.removeItem('fullName')
			router.push('/')
		},
        setLoading({commit}, isLoading){
            commit('SET_LOADING', isLoading)
        },
        setUser({commit}, user){
            commit('SET_USER', user)
        },
        extractErrorMessage({}, obj){
            let errorMessages = []
            //some api response has deeper level of error messages
            let keys = obj.hasOwnProperty('errors') ? Object.keys(obj.errors) : Object.keys(obj)

            keys.forEach(key => {
                if(obj.hasOwnProperty('errors')){
                    obj.errors[key].forEach(msg => {
                        errorMessages.push(msg)
                    })
                }
                else{
                    obj[key].forEach(msg => {
                        errorMessages.push(msg)
                    })
                }
            })

            return errorMessages
        },
        async getUserRole({state, commit}){
            await axios.get(`${state.endpoint}/user`)
            .then(response => {
                commit('SET_USER', response.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        }
    }
})