<template>
    <div>
        <div class="flex w-full justify-between">
            <span class="my-auto text-lg font-bold text-custom-blue">Audits</span>
            <div class="flex space-x-5">
                <Dropdown v-model="client" :options="clientListWithAll" :default="'All'" :label="'Client'" />
                <Dropdown v-model="year" :options="years" :default="'All'" :label="'Year'" />
                <Search @search="searchProjectAudits" @clear="searchProjectAudits" :placeholder="'Search by project name'" />
            </div>
        </div>
        <DataTable 
            :headers="headers" 
            :data="projectAudits" 
            :total-items="totalProjectAudits"
            :actions="['externalLink']"
            :external-link-path="'questionnaire'"
            :item="{id: 'audit_id', name: 'name'}"
            @changePage="getProjectAudits" />
    </div>
</template>
<script>
    import DataTable from '../../../../reusables/DataTable'
    import Search from '../../../../reusables/Search'
    import Dropdown from '../../../../reusables/Dropdown'
    import { mapState, mapGetters } from 'vuex'

    export default {
        components: {
            DataTable,
            Search,
            Dropdown
        },
        data: () => ({
            headers: [
                {
                    column: 'Audit Number',
                    prop: 'audit_id'
                },
                {
                    column: 'Project Name',
                    prop: 'project_name'
                },
                {
                    column: 'Client Name',
                    prop: 'client_name'
                },
                {
                    column: 'Year',
                    prop: 'year'
                },
                {
                    column: 'Status',
                    prop: 'admin_status'
                },
                {
                    column: 'Client Status',
                    prop: 'client_status'
                },
                {
                    column: 'P.A. Status',
                    prop: 'prior_auditor_status'
                },
                {
                    column: 'P.R. Status',
                    prop: 'peer_reviewer_status'
                }
            ],
            client: 'all',
            year: 'all',
            keyword: null
        }),
        computed: {
            ...mapState({
                projectAudits: state => state.projectModule.projectAudits,
                totalProjectAudits: state => state.projectModule.totalProjectAudits
            }),
            ...mapGetters('clientModule', ['clientListWithAll']),
            years(){
                const date = new Date()
                let years = [{
                    text: 'All',
                    value: 'all'
                }]
                let year = date.getFullYear()
                while(year >= 2017){
                    years.push(year.toString())
                    year--
                }
                return years
            }
        },
        watch: {
            client(){
                this.filterProjectAudits()
            },
            year(){
                this.filterProjectAudits()
            }
        },
        methods: {
            async searchProjectAudits(keyword){
                this.keyword = keyword
                await this.getProjectAudits(1)
            },
            async filterProjectAudits(){
                this.$store.dispatch('setLoading', true)
                this.$router.push({name: 'projects.audits', query: { client: this.client, year: this.year}}).catch(() => {})
                await this.$store.dispatch('projectModule/getProjectAuditsByFilter', {client: this.client, year: this.year})
                this.$store.dispatch('setLoading', false)
            },
            async getProjectAudits(page){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectModule/getProjectAudits', {
                    page: page,
                    keyword: this.keyword
                })
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            await this.getProjectAudits(1)
            await this.$store.dispatch('clientModule/getClientList')
        }
    }
</script>