<template>
    <div class="flex justify-between flex-grow">
        <div class="flex flex-col px-5 py-3 flex-grow max-w-5xl">
            <div class="mb-3 text-sm">
                <span class="badge" v-if="content.hasOwnProperty('status') && content.status == 'updated'">UPDATE</span>
                {{ content.question }}
                <!-- <span class="text-red-500 text-lg" v-if="content.is_required">*</span> -->
            </div>
            <template v-if="content.type == '1'">
                <template v-if="hasMultipleAnswers">
                    <div class="inline-flex space-x-5" v-for="(item, index) in multipleAnswers" :key="index">
                        <div>
                            <input @input="input" v-model="item.answer" type="radio" value="yes" :name="`answer-${index}-${content.id}`" :id="`yes-${content.id}`" :disabled="isDisabled">
                            <label :for="`yes-${content.id}`">Yes</label>
                        </div>
                        <div>
                            <input @input="input" v-model="item.answer" type="radio" value="no" :name="`answer-${index}-${content.id}`" :id="`no-${content.id}`" :disabled="isDisabled">
                            <label :for="`no-${content.id}`">No</label>
                        </div>
                        <div>
                            <textarea @input="input" v-model="item.comment" cols="40" rows="2" class="text-sm" :disabled="isDisabled"></textarea>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="inline-flex space-x-5">
                        <div>
                            <input @input="input" v-model="value.answer" type="radio" value="yes" :name="`answer-${content.id}`" :id="`yes-${content.id}`" :disabled="isDisabled">
                            <label :for="`yes-${content.id}`">Yes</label>
                        </div>
                        <div>
                            <input @input="input" v-model="value.answer" type="radio" value="no" :name="`answer-${content.id}`" :id="`no-${content.id}`" :disabled="isDisabled">
                            <label :for="`no-${content.id}`">No</label>
                        </div>
                        <div>
                            <textarea @input="input" v-model="value.comment" cols="40" rows="2" class="text-sm" :disabled="isDisabled"></textarea>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else-if="content.type == '2'">
                <template v-if="hasMultipleAnswers">
                    <div class="inline-flex items-center space-x-3">
                        <div class="text-xs space-x-2">
                            <span class="font-semibold">Uploaded Files:</span>
                            <div v-for="(files, index) in multipleAnswers" :key="`files-${index}`">
                                <span v-for="(file, idx) in files.filename" :key="`file-${idx}`">
                                    {{ file }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="inline-flex items-center space-x-3">
                        <div class="border inline-flex rounded px-2 py-1 w-80 items-center justify-between">
                            <template v-if="attachments.length != 0">
                                <span class="text-sm" v-if="attachments.length > 1">{{ attachments.length }} files</span>
                                <span v-else class="text-sm truncate">{{ attachments[0].name }}</span>
                            </template>
                            <template v-else>
                                <span class="text-sm text-gray-400">Upload here</span>
                            </template>
                            <input @change="setAttachments" type="file" :id="`attachment-${content.id}`" class="hidden" multiple :disabled="isDisabled">
                            <div class="inline-flex space-x-2 items-center">
                                <div class="cursor-pointer" v-if="attachments.length" @click="clearFiles">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <label :for="`attachment-${content.id}`" class="btn-small btn-secondary cursor-pointer">Upload</label>
                            </div>
                        </div>
                        <div class="text-xs space-x-2" v-if="uploadedFiles.length">
                            <span class="font-semibold">Uploaded Files:</span>
                            <span v-for="(filename, index) in uploadedFiles" :key="`file-${index}`">
                                {{ filename }}
                            </span>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else-if="content.type == '3'">
                <template v-if="hasMultipleAnswers">
                    <textarea v-model="item.answer" cols="30" rows="5" placeholder="Answer here" :disabled="isDisabled" v-for="(item, index) in multipleAnswers" :key="index"></textarea>
                </template>
                <template v-else>
                    <textarea @input="input" v-model="value.answer" cols="30" rows="5" placeholder="Answer here" :disabled="isDisabled"></textarea>
                </template>
            </template>
            <template v-else-if="content.type == '4'">
                <template v-if="hasMultipleAnswers">
                    <input v-model="item.answer" type="text" class="border rounded p-2 focus:outline-none" placeholder="URL" :disabled="isDisabled" v-for="(item, index) in multipleAnswers" :key="index" />
                </template>
                <template v-else>
                    <input @input="input" v-model="value.answer" type="text" class="border rounded p-2 focus:outline-none" placeholder="URL" :disabled="isDisabled" />
                </template>
            </template>
            <!-- <template v-else-if="content.type == '5'">
                <input @input="input" v-model="value.answer" type="text" class="focus:outline-none border rounded p-2" placeholder="Answer here" :disabled="isDisabled" />
            </template> -->
        </div>
        <div class="inline-flex space-x-3 mr-5 mt-3 text-custom-blue" v-if="isSuperAdminView">
            <button class="h-4 w-4 has-tooltip" @click="showEditDialog = true">
                <span class="tooltip">Edit</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
            </button>
            <button class="h-4 w-4 has-tooltip" @click="showDeleteConfirmation = true">
                <span class="tooltip">Delete</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </button>
        </div>
        <Dialog v-if="showEditDialog" @closeDialog="showEditDialog = false">
            <template v-slot:title>
                <span class="font-semibold">Questionnaire</span>
            </template>
            <template v-slot:body>
                <div class="m-5 text-custom-blue">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Question</span>
                            <span v-if="hasError('question')" class="text-xs text-red-500">{{ errors.question }}</span>
                        </div>
                        <textarea v-model="questionForm.question" cols="30" rows="5" placeholder="Enter question" :class="{'border-red-500': hasError('question') }"></textarea>
                    </div>
                    <div class="flex flex-col mt-3">
                        <div class="inline-flex justify-between mb-1">
                            <span class="mb-2 font-semibold">Answer Type</span>
                            <span v-if="hasError('type')" class="text-xs text-red-500">{{ errors.type }}</span>
                        </div>
                        <div class="inline-flex justify-left items-center space-x-2 p-2" :class="{'border-red-500 border rounded': hasError('type') }">
                            <div>
                                <input v-model="questionForm.type" type="radio" id="yesno" value="1" name="type">
                                <label for="yesno">Yes/No</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="file" value="2" name="type">
                                <label for="file">File Upload</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="text" value="3" name="type">
                                <label for="text">Text</label>
                            </div>
                            <div>
                                <input v-model="questionForm.type" type="radio" id="url" value="4" name="type">
                                <label for="url">URL</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex items-center space-x-2">
                            <input type="checkbox" v-model="questionForm.is_required" id="required">
                            <label for="required" class="font-semibold">Is required?</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="updateQuestion">Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showDeleteConfirmation" @deleteItem="deleteQuestion" @cancel="showDeleteConfirmation = false">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete this question?
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import Confirmation from './Confirmation'
    import Dialog from './Dialog'
    import { mapState } from 'vuex'

    export default {
        props: {
            content: {
                type: Object
            },
            isSuperAdminView: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: () => []
            },
            value: {
                type: Object,
                default: () => ({
                    answer: '',
                    comment: ''
                })
            },
            hasMultipleAnswers: {
                type: Boolean,
                default: false
            },
            multipleAnswers: {
                type: Array,
                default: () => []
            }
        },
        components: {
            Confirmation,
            Dialog
        },
        data: () => ({
            showDeleteConfirmation: false,
            showEditDialog: false,
            questionForm: {},
            errors: {},
            validated: false,
            errorMessage: '',
            attachments: [],
            uploadedFiles: []
        }),
        computed: {
            ...mapState({
                user: state => state.user
            })
        },
        methods: {
            editQuestion(){
                this.questionForm = this.content
            },
            async deleteQuestion(){
                this.$store.dispatch('setLoading', true)
                this.showDeleteConfirmation = false
                await this.$store.dispatch('auditProcedureModule/deleteQuestion', this.content.audit_procedure_id)
                await this.$store.dispatch('auditProcedureModule/getProcedures')
                this.$store.dispatch('setLoading', false)
            },
            async updateQuestion(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    this.showEditDialog = false
                    await this.$store.dispatch('auditProcedureModule/updateQuestion', this.questionForm)
                    .then(response => {
                        if(response.status == 200){
                            this.showEditDialog = false
                        }
                    })
                    await this.$store.dispatch('auditProcedureModule/getProcedures')
                    this.$store.dispatch('setLoading', false)
                }
            },
            validate(){
                this.errors = {}
                if(!this.questionForm.question){
                    this.errors.question = 'Required'
                }
                if(!this.questionForm.type){
                    this.errors.type = 'Required'
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
            setAttachments(file){
                this.attachments = file.target.files
                let filteredFiles = Array.from(file.target.files).filter(file => !this.uploadedFiles.includes(file.name))
                let filename = filteredFiles.map(file => file.name)
				this.$emit('input', {
                    files: file.target.files,
                    filename: [...this.uploadedFiles, ...filename],
                    folder: this.content.question 
                })
			},
            input(){
                this.$emit('input', this.value)
            },
            clearFiles(){
                this.attachments = []
                this.$emit('input', {})
            }
        },
        mounted(){
            this.questionForm = this.content
            if(this.content.type == '2'){ //FILE UPLOAD TYPE
                if(this.content.procedure_step_id == '2'){ //FRAUD RISK
                    const index = `individual_answer_${this.user.id}`
                    this.uploadedFiles = this.content.hasOwnProperty(index) && Object.keys(this.content[index]).length !== 0 ? this.content[index].filename : []
                }
                else{
                    this.uploadedFiles = this.content.hasOwnProperty('answer') && Object.keys(this.content.answer).length !== 0 ? this.content.answer.filename : []
                }
            }
        }
    }
</script>