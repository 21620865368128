<template>
    <div>
        <div class="flex w-full justify-between mb-5">
            <button class="inline-flex items-center text-custom-blue" @click="$router.go(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <span class="ml-2">Back</span>
            </button>
            <button class="btn btn-primary" @click="showCreateDialog">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                </svg>
                <span>Time</span>
            </button>
        </div>
        <template v-if="project">
            <div class="flex flex-col border bg-white rounded">
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Project</span>
                    <span class="ml-5 col-span-3">{{ project.name }}</span>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Client</span>
                    <span class="ml-5 col-span-3">{{ project.client_name }}</span>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Total Hours</span>
                    <span class="ml-5 col-span-3">{{ project.total_hours }}</span>
                </div>
                <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                    <span class="text-gray-400">Total Billed</span>
                    <span class="ml-5 col-span-3">$ {{ totalProjectBilled }}</span>
                </div>
            </div>
            <DataTable 
                :headers="headers"
                :data="project.timesheet"
                :actions="['edit', 'delete']"
                :has-pagination="false"
                :item="{id: 'id', name: 'date'}"
                @editItem="showEditDialog"
                @deleteItem="showDeleteConfirmation" />
        </template>
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>
                <span class="text-sm">Time</span>
            </template>
            <template v-slot:body>
                <div class="error" v-if="errorMessage.length">
                    <span v-for="(msg, index) in errorMessage" :key="`res-${index}`">{{ msg }}</span>
                </div>
                <div class="flex p-5 flex-col space-y-5">
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Date</span>
                            <span v-if="hasError('date')" class="text-xs text-red-500">{{ errors.date }}</span>
                        </div>
                        <div class="w-auto" :class="{'border rounded border-red-500': hasError('date') }">
                            <date-picker style="width: 100%" v-model="timesheetForm.date" valueType="YYYY-MM-DD" format="YYYY-MM-DD" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Start Time</span>
                            <span v-if="hasError('start_time')" class="text-xs text-red-500">{{ errors.start_time }}</span>
                        </div>
                        <input v-model="timesheetForm.start_time" placeholder="24 hour format (00:00)" type="text" class="input-text" autocomplete="nope" :class="{'border-red-500': hasError('start_time') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">End Time</span>
                            <span v-if="hasError('end_time')" class="text-xs text-red-500">{{ errors.end_time }}</span>
                        </div>
                        <input v-model="timesheetForm.end_time" placeholder="24 hour format (00:00)" type="text" class="input-text" autocomplete="nope" :class="{'border-red-500': hasError('end_time') }">
                    </div>
                    <div class="flex flex-col">
                        <div class="inline-flex justify-between mb-1">
                            <span class="font-semibold">Note</span>
                            <span v-if="hasError('note')" class="text-xs text-red-500">{{ errors.note }}</span>
                        </div>
                        <textarea rows="3" cols="40" v-model="timesheetForm.note" autocomplete="nope" />
                    </div>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn btn-secondary" @click="save" v-if="!editMode">Save</button>
                <button class="btn btn-secondary" @click="update" v-else>Update</button>
            </template>
        </Dialog>
        <Confirmation v-if="showConfirmation" @deleteItem="deleteTime" @cancel="showConfirmation = false">
            <template v-slot:message>
                <div class="text-sm">
                    Would you like to delete this time?
                </div>
            </template>
        </Confirmation>
    </div>
</template>
<script>
    import { mapState, mapGetters } from 'vuex'
    import DataTable from '../../../reusables/DataTable'
    import Dialog from '../../../reusables/Dialog'
    import DatePicker from 'vue2-datepicker'
    import Confirmation from '../../../reusables/Confirmation'
    import dayjs from 'dayjs'

    export default {
        components: {
            DataTable,
            Dialog,
            DatePicker,
            Confirmation
        },
        data: () => ({
            headers: [
                {
                    column: 'Date',
                    prop: 'date'
                },
                {
                    column: 'From',
                    prop: 'user_full_name'
                },
                {
                    column: 'Start Time',
                    prop: 'start_time'
                },
                {
                    column: 'End Time',
                    prop: 'end_time'
                },
                {
                    column: 'Duration',
                    prop: 'duration'
                },
                {
                    column: 'Hours',
                    prop: 'hours'
                },
                {
                    column: 'Note',
                    prop: 'note'
                },
            ],
            showDialog: false,
            editMode: false,
            errorMessage: '',
            errors: {},
            timesheetForm: {},
            showConfirmation: false,
            itemToBeDeleted: {},
            validated: false
        }),
        computed: {
            ...mapState({
                project: state => state.projectTimeSheetModule.project
            }),
            ...mapGetters('projectTimeSheetModule', ['totalProjectBilled']),
            ...mapGetters(['isSuperAdminOrAdmin'])
        },
        methods: {
            async getTimeSheet(){
                this.$store.dispatch('setLoading', true)
                await this.$store.dispatch('projectTimeSheetModule/getProjectTimeSheet', {
                    projectId: this.$route.params.id,
                    byUser: !this.isSuperAdminOrAdmin
                })
                this.$store.dispatch('setLoading', false)
            },
            async save(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    this.showDialog = false
                    await this.$store.dispatch('projectTimeSheetModule/create', {
                        ...this.timesheetForm,
                        project_id: this.$route.params.id
                    })
                    await this.getTimeSheet()
                    this.$store.dispatch('setLoading', false)
                }
            },
            async update(){
                this.validate()
                this.validated = true
                if(Object.keys(this.errors).length == 0){
                    this.$store.dispatch('setLoading', true)
                    this.showDialog = false
                    await this.$store.dispatch('projectTimeSheetModule/update', this.timesheetForm)
                    await this.getTimeSheet()
                    this.$store.dispatch('setLoading', false)
                }
            },
            async deleteTime(){
                this.$store.dispatch('setLoading', true)
                this.showConfirmation = false
                this.showDialog = false
                await this.$store.dispatch('projectTimeSheetModule/delete', this.itemToBeDeleted.id)
                await this.getTimeSheet()
                this.$store.dispatch('setLoading', false)
            },
            validate(){
                this.errors = {}
                const regex = /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/
                if(this.timesheetForm.date == '' || this.timesheetForm.date == null){
                    this.errors.date = 'Required'
                }
                else{
                    if(this.timesheetForm.start_time == ''){
                        this.errors.start_time = 'Required'
                    }

                    if(!regex.test(this.timesheetForm.start_time)){
                        this.errors.start_time = 'Invalid format (24 hour format)'
                    }

                    if(this.timesheetForm.end_time != ''){
                        let startTime = dayjs(`${this.timesheetForm.date} ${this.timesheetForm.start_time}`)
                        let endTime = dayjs(`${this.timesheetForm.date} ${this.timesheetForm.end_time}`)
                        if(endTime.diff(startTime) < 0){
                            this.errors.end_time = 'End time should be later than start time'
                        }

                        if(!regex.test(this.timesheetForm.end_time)){
                            this.errors.end_time = 'Invalid format (24 hour format)'
                        }
                    }
                }
            },
            hasError(field){
                return this.errors.hasOwnProperty(field) && this.errors[field] != ''
            },
            showCreateDialog(){
                this.editMode = false
                this.timesheetForm = {}
                this.errorMessage = ''
                this.showDialog = true
            },
            showEditDialog(time){
                this.editMode = true
                // Object.assign(this.timesheetForm, time)
                this.timesheetForm = time
                this.showDialog = true
            },
            showDeleteConfirmation(time){
                this.itemToBeDeleted = time
                this.showConfirmation = true
            }
        },
        async mounted(){
            await this.getTimeSheet()
        }
    }
</script>