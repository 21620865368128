<template>
    <div>
        <div class="flex w-full justify-between mb-5">
            <button class="inline-flex items-center text-custom-blue" @click="$router.go(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <span class="ml-2">Back</span>
            </button>
            <button class="btn btn-primary" @click="showDialog = true">Set Up Audit</button>
        </div>
        <div class="flex flex-col border bg-white rounded">
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Client Name</span>
                <span class="ml-5 col-span-3">{{ client.name }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Client Email</span>
                <span class="ml-5 col-span-3">{{ client.email }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Contact Number</span>
                <span class="ml-5 col-span-3">{{ client.contact_number }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Address Line 1</span>
                <span class="ml-5 col-span-3">{{ client.address_line_1 }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Address Line 2</span>
                <span class="ml-5 col-span-3">
                    <template v-if="client.address_line_2 != ''">
                        {{ client.address_line_2 }}
                    </template>
                    <template v-else>-</template>
                </span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">City</span>
                <span class="ml-5 col-span-3">{{ client.city }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">State</span>
                <span class="ml-5 col-span-3">{{ client.state }}</span>
            </div>
            <div class="grid grid-cols-4 border-b pl-10 items-center py-3 text-sm">
                <span class="text-gray-400">Zip Code</span>
                <span class="ml-5 col-span-3">{{ client.zip_code }}</span>
            </div>
        </div>
        <div class="flex w-full justify-start my-5">
            <span class="my-auto text-lg font-bold text-custom-blue">Users</span>
        </div>
        <DataTable v-if="client.hasOwnProperty('users_count')" :headers="userHeaders" :data="client.users" :total-items="client.users_count" />
        <div class="flex w-full justify-start my-5">
            <span class="my-auto text-lg font-bold text-custom-blue">Projects</span>
        </div>
        <DataTable v-if="client.hasOwnProperty('projects_count')" :headers="projectHeaders" :data="client.projects" :total-items="client.projects_count" />
        <Dialog v-if="showDialog" @closeDialog="showDialog = false">
            <template v-slot:title>Set Up Audit</template>
            <template v-slot:body>
                <div class="flex p-5 flex-col space-y-5">
                    <model-select
                        :options="projectOptions"
                        v-model="projectId"
                        :placeholder="'Choose a project'"
                    ></model-select>
                    <select v-model="year">
                        <option :value="year" v-for="(year, index) in years" :key="index">{{ year }}</option>
                    </select>
                </div>
            </template>
            <template v-slot:action>
                <button class="btn-small btn-secondary" @click="sendAudit">Send</button>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import DataTable from '../../../reusables/DataTable'
    import Dialog from '../../../reusables/Dialog'
    import { ModelSelect } from 'vue-search-select'
    import { mapState } from 'vuex'

    export default {
        components: {
            DataTable,
            Dialog,
            ModelSelect
        },
        data: () => ({
            showDialog: false,
            details: [
                'Client Name',
                'Client Email',
                'Contact Number',
                'Address Line 1',
                'Address Line 2',
                'City',
                'State',
                'Zip Code'
            ],
            userHeaders: [
                {
                    column: 'Full Name',
                    prop: 'full_name'
                },
                {
                    column: 'Email',
                    prop: 'email'
                },
                {
                    column: 'Type',
                    prop: 'role'
                },
            ],
            projectHeaders: [
                {
                    column: 'Project Name',
                    prop: 'name'
                },
                {
                    column: 'Project ID',
                    prop: 'project_id'
                },
                {
                    column: 'Client Name',
                    prop: 'client_name'
                },
                {
                    column: 'Year',
                    prop: 'year'
                },
                {
                    column: 'Type',
                    prop: 'type_name'
                }
            ],
            projectId: '',
            year: ''
        }),
        computed: {
            ...mapState({
                client: state => state.clientModule.client
            }),
            projectOptions(){
                return this.client.projects.map(project => ({
                    value: project.project_id,
                    text: project.name
                }))
            }
        },
        methods: {
            sendAudit(){
                this.showDialog = false
                this.$store.dispatch('setLoading', true)
                this.$store.dispatch('projectModule/sendAudit', {
                    client_id: this.$route.params.id,
                    project_id: this.projectId,
                    year: this.year
                })
                this.$store.dispatch('setLoading', false)
            }
        },
        async mounted(){
            this.$store.dispatch('setLoading', true)
            await this.$store.dispatch('clientModule/getClient', this.$route.params.id)
            this.$store.dispatch('setLoading', false)
        }
    }
</script>