<template>
    <div class="flex justify-center items-center">
        <div class="flex flex-col space-y-4 border rounded bg-white w-96 h-80 p-8">
            <input v-model="passwordForm.newPassword" type="password" placeholder="New Password" class="input-text" :class="{'border-red-500': false }">
            <input v-model="passwordForm.newPasswordConfirmation" type="password" placeholder="Confirm Password" class="input-text" :class="{'border-red-500': false }">
            <button class="btn" :class="isPasswordMatched ? 'btn-secondary' : 'btn-disabled'" :disabled="!isPasswordMatched" @click="resetPassword">Change Password</button>
            <router-link :to="{ name: 'login' }" class="text-custom-blue cursor-pointer text-sm underline">Back to Login</router-link>
        </div>
    </div>
</template>
<script>
    export default {
        data: () => ({
            passwordForm: {
                newPassword: '',
                newPasswordConfirmation: ''
            },
            isPasswordMatched: false
        }),
        watch: {
            passwordForm: {
                deep: true,
                handler(){
                    this.isPasswordMatched = this.passwordForm.newPassword == this.passwordForm.newPasswordConfirmation
                }
            }
        },
        methods: {
            async resetPassword(){
                this.$store.dispatch('setLoading', true)
                let email = this.$route.query.email
                let token = this.$route.params.token
                await this.$store.dispatch('authModule/resetPassword', {
                    ...this.passwordForm,
                    email,
                    token
                })
                this.$store.dispatch('setLoading', false)
            }
        }
    }
</script>