import axios from 'axios'
import dayjs from 'dayjs'

export default {
    namespaced: true,
    state: {
        project: {
            timesheet: []
        },
        user: {
            rate_history: []
        },
        timesheet: []
    },
    getters: {
        totalProjectBilled(state){
            if(state.project.hasOwnProperty('timesheet')){
                return state.project.timesheet.map(row => row.hours * row.hourly_rate).reduce((prev, current) => prev+current, 0).toFixed(2)
            }

            return 0
        }
    },
    mutations: {
        SET_PROJECT_TIMESHEET(state, timesheet){
            state.timesheet = timesheet
        },
        SET_PROJECT(state, project){
            state.project = project
        },
        SET_USER_TIME_SHEET(state, user){
            state.user = user
        }
    },
    actions: {
        async getProjectTimeSheet({commit}, payload){
            await axios.get(`${this.state.endpoint}/project/timesheet/project/${payload.projectId}/${payload.byUser}`)
            .then(response => {
                commit('SET_PROJECT', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async getUserTimeSheet({commit}, payload){
            await axios.get(`${this.state.endpoint}/project/timesheet/user/${payload.month}/${payload.year}/${payload.userId}`)
            .then(response => {
                commit('SET_USER_TIME_SHEET', response.data.data)
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
            })
        },
        async update({}, payload){
            return await axios.patch(`${this.state.endpoint}/project/timesheet/${payload.id}`, payload)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async create({}, payload){
            return await axios.post(`${this.state.endpoint}/project/timesheet`, payload)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async delete({}, projectTimeSheetId){
            return await axios.delete(`${this.state.endpoint}/project/timesheet/${projectTimeSheetId}`)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
        async updateHourlyRate({}, payload){
            return await axios.patch(`${this.state.endpoint}/rate-history/${payload.id}`, payload)
            .then(response => {
                this.commit('SET_SNACKBAR', {
                    message: response.data.message,
                    color: 'green'
                })
                return true
            })
            .catch(err => {
                if(err.response.status == 401){
                    this.dispatch('redirectToLogin')
                }
                if(err.response.data){
                    return this.dispatch('extractErrorMessage', err.response.data)
                }
            })
        },
    }
}